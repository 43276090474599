import React from "react";
import { DateRange } from "../DateRangePicker";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { WithStyles, withStyles, Typography, Card, CardContent, Grid, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@material-ui/core";
import { styles } from "../../Styles";
import { AbstractLoadingComponent } from "../AbstractLoadingComponent";
import ApplicationState from "../../ApplicationState";
import { CartesianGrid, XAxis, YAxis, Tooltip, Bar, ReferenceArea, TooltipProps, AxisDomain, XAxisProps, RectangleProps, ComposedChart, Line } from "recharts";
import moment from 'moment-timezone';
import { colors } from "../../Utils";
import { GraphInfo, DataPoint, DataSerie } from "../../Services/ServerFacadeStats";

export interface ChartsComponentProps {
    appState: ApplicationState;
    dateRange: DateRange;
    dataUrl?: string;
    graphs: GraphInfo[];
    legendKey: string,
    showLegend: boolean;
    valueExtractorFactory: DataRowValueExtractorFactory;
    dateRangeSelected: (newValue: DateRange) => void;
}

export interface DataRowValueExtractorFactory {
    newInstance(graphInfo: GraphInfo): DataRowValueExtractor;
}

export interface DataRowValueExtractor {
    timestamp(row: any): number;
    headline(row: any): string;
    subheader(row: any): string;
    additionalValues(dataSeries: string, dp: DataPoint): Map<string, string>;
    showValue(row: any): boolean;
    value(row: any): string;
    dataPointColor(graph: GraphInfo, ds: DataSerie, dp: DataPoint): string | undefined;
}

@observer
class ChartsComponentImpl extends AbstractLoadingComponent<ChartsComponentProps & WithStyles<typeof styles>, any> {

    @observable
    private graphContainerWidth?: number;
    // Used to get container width
    private graphContainerRef: HTMLDivElement | null = null;

    @observable
    private selectedDateRange?: DateRange;

    public componentDidMount() {
        if (this.graphContainerRef) {
            this.graphContainerWidth = this.graphContainerRef.offsetWidth - 10;
        }
        this.listenOnResize(() => {
            if (this.graphContainerRef) {
                this.graphContainerWidth = this.graphContainerRef.offsetWidth - 10;
            }
        });
    }

    public componentWillUnmount() {
        this.stopListenOnResize();
    }

    protected doRender() {
        if (!this.graphContainerWidth) {
            return (<div ref={el => this.graphContainerRef = el} />);
        }

        var startVal = this.props.dateRange.start.getTime();
        var endVal = this.props.dateRange.end.getTime();
        var sameDay = moment(startVal).format('YYYY/MM/DD') === moment(endVal).format('YYYY/MM/DD');

        // Prepare y axis min/max values
        let usePercentages = this.props.appState.preferences!.usePercentages;
        let domain: [AxisDomain, AxisDomain];
            domain = [
            (dataMin: any) => {
                var min = dataMin;
                if(dataMin > 0) {
                    var diff = (dataMin/20);
                    min = Math.max(0, Math.trunc((dataMin - diff) * 10000) / 10000);
                    if(min === undefined || isNaN(min) || (min > 0 && min < 0.1)) {
                        min = 0;
                    }
                } else if(dataMin < 0) {
                    var diff2 = (dataMin/20);
                    min = Math.min(0, Math.trunc((dataMin + diff2) * 100) / 100);
                }
                if(min < 100) {
                    min = Math.floor(min);
                }
                if(min > 100) {
                    min = Math.ceil(min);
                }
                return min;
            }, 
            (dataMax: any) => {
                if(dataMax <= 0) {
                    return dataMax;
                }
                var diff = (dataMax/20);
                var max = Math.max(0, Math.trunc((dataMax + diff) * 10000) / 10000);
                if(max > 100) {
                    max = Math.ceil(max);
                }
                return max;
            }
        ];

        // Prepare graph sizes
        let graphWidth = this.props.showLegend ? this.graphContainerWidth! - 350 : this.graphContainerWidth;
        if(graphWidth < 400) {
            graphWidth = this.graphContainerWidth;
        }
        let graphHeight = 400;
        if(graphHeight > graphWidth) {
            graphHeight = graphWidth - 100;
        }

        const flowMode = this.props.appState.preferences!.flowMode;
        let classes = this.props.classes;
        return (
            <div key="graph" ref={el => this.graphContainerRef = el}>
                <Grid container direction={'column'}>
                    <Grid item>
                        {this.props.dataUrl && (<Button variant="outlined" href={this.props.dataUrl}>Download data</Button>)}
                    </Grid>
                    {this.props.graphs.map((graphInfo, idx) => {
                        let ve = this.props.valueExtractorFactory.newInstance(graphInfo);
                        let data: any[] = graphInfo.data.map(dp => {
                            let ret: any = {
                                xAxisValue: dp.xAxisValue
                            };
                            for(var key in dp.yAxisValues) {
                                ret[key] = dp.yAxisValues[key];
                            }
                            return ret;
                        });
                        return <Grid item key={idx}>
                            <div className={classes.graphContainer}>
                                <Grid container spacing={1} justify={'flex-start'}>
                                    <Grid item xs={12}>
                                        <Typography component="h2" variant="h6" color="primary" gutterBottom>{graphInfo.name}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <ComposedChart
                                            width={graphWidth} height={graphHeight}
                                            data={data}
                                            margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
                                            onMouseDown={(e) => this.handleTimeSelectionStart(e.activeLabel)}
                                            onMouseMove={(e) => this.handleTimeSelectionChange(e.activeLabel)}
                                            onMouseUp={(e) => this.handleTimeSelectionEnd()}
                                            maxBarSize={20}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                            <XAxis
                                                dataKey="xAxisValue"
                                                domain={[startVal, endVal]}
                                                tick={(props: XAxisProps, ctx: any) => this.customXAxisTick(props, !sameDay)}
                                                interval={"preserveStartEnd"}
                                                type={'number'}
                                            />
                                            <YAxis 
                                                domain={domain} 
                                                tickCount={10} 
                                                label={{ value: usePercentages ? "%" : "ppm", angle: -90, position: "left" }} 
                                                tickFormatter={(t) => t.toLocaleString('da')}
                                            />
                                            <Tooltip content={(props, ctx) => this.customTooltip(props, ve, graphInfo)} />
                                            {graphInfo.dataSeries.map((ds, idx2) => {
                                                if(!flowMode)
                                                    return (
                                                        <Bar
                                                            key={ds.key} 
                                                            dataKey={ds.key} 
                                                            isAnimationActive={false}
                                                            fill={colors[idx2 * 5 % colors.length]} 
                                                            shape={(props) => this.renderBar(ve, graphInfo, ds, props)}
                                                            />
                                                    );
                                                else
                                                    return(
                                                        <Line
                                                            key={ds.key} 
                                                            dataKey={ds.key} 
                                                            label={false}
                                                            dot={true}
                                                            stroke={colors[idx2 * 5 % colors.length]}
                                                            isAnimationActive={false} 
                                                            strokeWidth={2}
                                                            connectNulls={true}
                                                        />
                                                    );
                                            }
                                            )}
                                            {graphInfo.dataSeries.map((ds, idx) => 
                                                graphInfo.alarms.filter(a => a.hasLevel).map(a => 
                                                    <Line
                                                        key={ds.key+a.key}  
                                                        dataKey={ds.key + '_' + a.key + '_level'} 
                                                        label={false}
                                                        dot={false}
                                                        stroke="red"
                                                        isAnimationActive={false} 
                                                        strokeWidth={2}
                                                    />))}
                                            {this.selectedDateRange && (
                                                <ReferenceArea x1={this.selectedDateRange.start.getTime()} x2={this.selectedDateRange.end.getTime()} strokeOpacity={0.3} />
                                            )}
                                        </ComposedChart>
                                    </Grid>
                                    {this.props.showLegend && this.renderLegend(idx, graphInfo)}
                                </Grid>
                            </div>
                        </Grid>;
                        }
                    )}
                </Grid>
            </div>
            );
    }

    private renderBar(ve: DataRowValueExtractor, graph: GraphInfo, ds: DataSerie, props: RectangleProps) {
        let x = props.x || 0;
        let y = props.y || 0;
        let width = Math.max(1, props.width || 0);
        let height = props.height || 0;
        let fill = props.fill;
        let xAxisValue = (props as any).payload.xAxisValue;
        if(height === 0) {
            return (<React.Fragment />);
        }
        let dp = graph.data.find(dp => dp.xAxisValue === xAxisValue);
        if(dp) {
            let newColor = ve.dataPointColor(graph, ds, dp);
            if(newColor) {
                fill = newColor;
            }
        }

        let path = 
        `M${x},${y}
        L${x},${y + height}
        L${x + width},${y + height}
        L${x + width},${y}
        Z`;

        return <path d={path} stroke={'none'} fill={fill}/>
    }

    private renderLegend(graphIdx: number, graphInfo: GraphInfo) {
        let classes = this.props.classes;
        const showValues = graphInfo.dataSeries.find(ds => ds.avg !== -1 || ds.stdDev !== -1) !== undefined;
        return (
            <Grid item>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{this.props.legendKey}</TableCell>
                            { showValues &&
                            <React.Fragment>
                                <TableCell align="right">Avg</TableCell>
                                <TableCell align="right">STD</TableCell>
                            </React.Fragment>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                {graphInfo.dataSeries.map((ds, idx) => {
                    return (
                        <TableRow key={idx} style={{whiteSpace:'nowrap'}}>
                            <TableCell><div style={{backgroundColor: colors[idx * 5 % colors.length]}} className={classes.smallSquare}/>{ds.name}</TableCell>
                            { showValues &&
                            <React.Fragment>
                                <TableCell align="right">{ds.avg}</TableCell>
                                <TableCell align="right">{ds.stdDev}</TableCell>
                            </React.Fragment>
                            }
                        </TableRow>
                        );
                    })}
                    </TableBody>
                </Table>
            </Grid>
        );
    }

    private customXAxisTick(props: any, showDate: boolean) {
        var lable = moment(props.payload.value).format(showDate ? 'DD/MM HH:mm' : 'HH:mm');
        var x = props.x;
        var y = props.y;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor={'middle'} fill="#666">{lable}</text>
            </g>
        );
    }

    private customTooltip(props: TooltipProps, ve: DataRowValueExtractor, graphInfo: GraphInfo) {
        //var key = props.label + '_' + (props.payload && props.payload.length > 0 && props.payload[0].dataKey);
        var xAxisValue = props.label;
        var dsKey = ((props.payload && props.payload.length > 0) ?  props.payload[0].dataKey : '')!.toString();
        var dp = graphInfo.data.find(dp => dp.xAxisValue === xAxisValue);
        var row: any = dp?.rowValues[dsKey];
        if (!dp || !row) {
            return (<div>{props.label}</div>);
        }

        // Prepare additional values
        let additionalValues = ve.additionalValues(dsKey, dp);
        let additionalValuesFields: React.ReactNode[] = [];
        additionalValues.forEach((v, k) => additionalValuesFields.push(<React.Fragment key={k}>{k}: {v}<br /></React.Fragment>));

        let showValue = ve.showValue(row);

        let userTz = moment.tz.guess();
        return (
            <Card>
                <CardContent>
                    <Typography color="textSecondary" gutterBottom>{moment.utc(ve.timestamp(row)).tz(userTz).format('YYYY/MM/DD HH:mm')}</Typography>
                    <Typography variant="h5" component="h2">{ve.headline(row)}</Typography>
                    <Typography color="textSecondary">{ve.subheader(row)}</Typography>
                    
                    <Typography variant="body2" component="p">
                        {additionalValuesFields}
                        { showValue && <b>Concentration: {ve.value(row)}</b>} <br /> 
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    private handleTimeSelectionStart(selectionPoint: any) {
        this.selectedDateRange = {
            start: moment(selectionPoint).toDate(),
            end: moment(selectionPoint).toDate()
        };
    }
    private handleTimeSelectionChange(selectionPoint: any) {
        if (this.selectedDateRange)
            this.selectedDateRange.end = moment(selectionPoint).toDate();
    }

    @action
    private handleTimeSelectionEnd() {
        let start = this.selectedDateRange!.start;
        let end = this.selectedDateRange!.end; 
        if(end.getTime() < start.getTime()) {
            let t = start;
            start = end;
            end = t;
        }
        this.props.dateRangeSelected({ start: start, end: end });
        this.selectedDateRange = undefined;
    }
}

export const ChartsComponent = withStyles(styles)(ChartsComponentImpl);
