import React from "react";
import { observer } from "mobx-react";
import { AbstractPopoverComponent } from "./AbstractPopoverComponent";
import { withStyles, Grid, Paper, List, ListItem, ListItemIcon, Checkbox, ListItemText, Link, Button } from "@material-ui/core";
import { styles } from "../../Styles";
import { observable } from "mobx";

export interface GroupSplitSelectorProps {
    splitByKeys: string[];
    dataSeriesKeys: string[];
    selectableValues: Map<string, string>;
    valueChanged: (splitByKeys: string[], dataSeriesKeys: string[]) => void;
}

@observer
class GroupSplitSelectorImpl extends AbstractPopoverComponent<GroupSplitSelectorProps> {

    @observable
    private availableKeys: string[] = [];

    @observable
    private checkedAvailableKeys: string[] = [];

    @observable
    private splitByKeys: string[] = [];
    @observable
    private checkedSplitByKeys: string[] = [];

    @observable
    private dataSeriesKeys: string[] = [];
    @observable
    private checkedDataSeriesKeys: string[] = [];

    protected openPopover(target: HTMLButtonElement | null) {
        this.splitByKeys = Object.assign([], this.props.splitByKeys);
        this.dataSeriesKeys = Object.assign([], this.props.dataSeriesKeys);
        this.availableKeys = [];
        this.props.selectableValues.forEach((v, k) => {
            if(!(this.splitByKeys.includes(k) || this.dataSeriesKeys.includes(k))) {
                this.availableKeys.push(k);
            }
        });
        super.openPopover(target);
    }

    protected renderDescription(): string {
        var ret = '';
        ret += 'Graph: ';
        ret += this.props.splitByKeys.map(k => this.props.selectableValues.get(k)).join(',');
        ret += ' - Data series: ';
        ret += this.props.dataSeriesKeys.map(k => this.props.selectableValues.get(k)).join(',');
        return ret;
    }

    protected renderPopoverContent(): React.ReactNode {
        let classes = this.props.classes;
        return (
            <Grid container className={classes.selectionContainer} spacing={2}>
                <Grid item>
                    Available values
                    {this.renderList(this.availableKeys, this.checkedAvailableKeys)}
                </Grid>
                <Grid item>
                    Operations<br />
                    <Link 
                        className={classes.button} 
                        component="button" 
                        variant="inherit" 
                        color="inherit" 
                        onClick={() => this.handleValuesMove(this.checkedAvailableKeys, this.availableKeys, this.splitByKeys)}>
                            Add to graph key
                    </Link><br />
                    <Link 
                        className={classes.button} 
                        component="button" 
                        variant="inherit" 
                        color="inherit" 
                        onClick={() => this.handleValuesMove(this.checkedAvailableKeys, this.availableKeys, this.dataSeriesKeys)}>
                            Add to dataserie
                    </Link><br />
                    <Link 
                        className={classes.button} 
                        component="button" 
                        variant="inherit" 
                        color="inherit" 
                        onClick={() => {
                            this.handleValuesMove(this.checkedSplitByKeys, this.splitByKeys, this.availableKeys);
                            this.handleValuesMove(this.checkedDataSeriesKeys, this.dataSeriesKeys, this.availableKeys);
                        }}>
                            Remove
                    </Link><br />
                </Grid>
                <Grid item>
                    Graph pr.
                    {this.renderList(this.splitByKeys, this.checkedSplitByKeys)}
                </Grid>
                <Grid item>
                    Dataserie values
                    {this.renderList(this.dataSeriesKeys, this.checkedDataSeriesKeys)}
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {this.props.valueChanged(this.splitByKeys, this.dataSeriesKeys); this.closePopover(); }}
                        disabled={!this.isFormValid()}>
                        Apply
                    </Button>
                </Grid>
            </Grid>
        );
    }

    protected isFormValid(): boolean {
        return this.splitByKeys.length > 0 && this.dataSeriesKeys.length > 0;
    }

    protected handleValuesMove(checkedValues: string[], srcList: string[], targetList: string[]) {
        checkedValues.forEach(k => srcList.splice(srcList.indexOf(k), 1));
        checkedValues.forEach(k => targetList.push(k)); 
        checkedValues.splice(0);
    }

    protected renderList(containedValues: string[], checkedValues: string[]) {
        let classes = this.props.classes;
        return (
            <Paper>
                <List dense component={'div'} role={'list'}>
                    {containedValues.map((v, idx) => 
                    <ListItem key={v} role={'listitem'} button onClick={(e) => this.handleCheck(checkedValues, v)} className={classes.multiListItem}>
                        <ListItemIcon className={classes.multiListSelectIcon}>
                            <Checkbox
                                checked={checkedValues.includes(v)}
                                tabIndex={-1}
                                disableRipple={true}
                                className={classes.multiListSelectCheckBox}
                            />
                            </ListItemIcon>
                        <ListItemText primary={this.props.selectableValues.get(v)} />
                    </ListItem>
                    )}
                </List>
            </Paper>
        );
    }

    protected handleCheck(values: string[], v: string) {
        let idx = values.indexOf(v);
        if(idx !== -1) {
            values.splice(idx, 1);
        } else {
            values.push(v);
        }
    }

}
export const GroupSplitSelector = withStyles(styles)(GroupSplitSelectorImpl);
