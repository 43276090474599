import React, { useEffect, useState } from "react";
import { Button, Checkbox, createStyles, Dialog, FormControlLabel, Grid, makeStyles, Switch, Table, TableBody, TableCell, TableHead, TableRow, WithStyles, withStyles } from "@material-ui/core";
import { CellValue } from '@material-ui/data-grid';
import { styles } from "../../Styles";
import ApplicationState from "../../ApplicationState";
import { ChemicalStatistics, SampleQuery, SampleStatistics } from "../../Services/ServerFacadeSampleManager";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import DeviceStatisticsController from "./DeviceStatisticsController";
import { getObject, setObject } from "../../Utils";

export interface SampleListProps extends RouteComponentProps<any>, WithStyles<typeof styles> {
    appState: ApplicationState;
}

const useStyles = makeStyles((theme) => createStyles({
    sampleList: {
        width: '100%'
    },
    rightBorder: {
        borderRight: '1px solid',
    },
    buttonWithSpacing: {
        margin: 5
    },
    normalRow: {
    },
    okRow: {
        backgroundColor: 'green',
    },
  }));


const SampleListFunc = (props: SampleListProps) => {
    const [selectedCompany, setSelectedCompany] = useState(-1);
    const [query, _] = useState<SampleQuery>(getObject("SampleListImpl.query", {
        page: 1,
        pageSize: 50,
        includeProcessed: false,
        includeUnmeasuredSamples: true,
    }));
    const [samples, setSamples] = useState<SampleStatistics[] | undefined>(undefined);
    const [chemicals, setChemicals] = useState<string[]>([]);

    const [selectedSamples, setSelectedSamples] = useState<number[]>([]);

    const [loading, setLoading] = useState(false);
    const [showingDeviceStats, setShowingDeviceStats] = useState(false);

    const classes = useStyles();

    const loadData = (sc: number | undefined = undefined) => {
        if(loading) return;
        persistState();
        if(!sc) sc = selectedCompany;
        if(sc === -1) {
            setSamples([]);
            return;
        }

        setLoading(true);
        const sm = props.appState.serverFacade.sampleManager();
        sm.listSamples(
            sc, 
            query, 
            (samples) => {
                setSelectedSamples([]);
                setSamples(samples);
                setChemicals(Array.from(new Set(samples.flatMap(s => s.chemicals).map(c => c.name))));
                setLoading(false);
            },
            (reason) => {
                props.appState.snackbarMessage = 'Unable to load samples: ' + reason;
                setLoading(false);
            });
    };

    const renderDeviceStatistics = () => {
        return <>
            <Dialog open={showingDeviceStats} onClose={() => setShowingDeviceStats(false)} maxWidth={'xl'}>
                <DeviceStatisticsController appState={props.appState} selectedCompany={selectedCompany} />
            </Dialog> 
        </>;
    }

    const markAsProcessed = () => {
        setLoading(true);
        const sm = props.appState.serverFacade.sampleManager();
        sm.markAsProcessed(
            selectedCompany,
            selectedSamples,
            () => {
                setLoading(false);
                loadData();
            },
            (reason) => {
                props.appState.snackbarMessage = reason;
                setLoading(false);
            }
        );
    }

    const downloadAsExcel = () => {
        setLoading(true);
        const sm = props.appState.serverFacade.sampleManager();
        sm.prepareExcelDownload(
            selectedCompany,
            {
                page: 0,
                pageSize: 0,
                includeProcessed: true,
                includeUnmeasuredSamples: true,
            },
            (url) => {
                var a: HTMLLinkElement = document.createElement('A') as HTMLLinkElement;
                a.href = url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setLoading(false);
            },
            (reason) => {
                props.appState.snackbarMessage = reason;
                setLoading(false);
            }
        );

    }

    const persistState = (): void => {
        setObject("SampleListImpl.query", query);
    }

    useEffect(() => {
        var sc: number | undefined = selectedCompany === -1 ? undefined : selectedCompany;
        if(selectedCompany === -1 && props.appState.authenticatedCompanies && props.appState.authenticatedCompanies.length > 0) {
            if(props.appState.authenticatedCompanies.find(c => c.id === 1)) {
                sc = 1;
            } else {
                sc = props.appState.authenticatedCompanies[0].id;
            }
            setSelectedCompany(sc);
        }
        if(samples === undefined) {
            loadData(sc);
        }
    });
    

    // Rendering

    if(loading) {
        return <p>Loading...</p>;
    }
    const isAllSelected = () => samples?.filter(s => !selectedSamples.includes(s.id)).length === 0;

    const fc = (s: SampleStatistics, chemicalName: string): ChemicalStatistics | undefined => {
        return s.chemicals.find(c => c.name === chemicalName);
    };
    const vf = (val: number | undefined): CellValue => 
        val === undefined ? undefined : Math.round(val);
    const df = (str: string | undefined): string | undefined => {
        if(str === undefined) return undefined;
        const d: Date = new Date(str);
        return `${d.getFullYear()}/${d.getMonth()+1}/${d.getDate()}`;
    }
    return (
    <React.Fragment>
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Button variant={'contained'} color={'primary'} className={classes.buttonWithSpacing} component={({innerRef,...props}) => <Link {...props} to={`/sample/new/${selectedCompany}`}/>}>New</Button>
                <Button variant={'contained'} color={'default'} className={classes.buttonWithSpacing} onClick={() => markAsProcessed() } disabled={selectedSamples.length === 0}>Mark as processed</Button>
                <Button variant={'contained'} color={'default'} className={classes.buttonWithSpacing} onClick={() => downloadAsExcel() }>Export</Button>
                <Button variant={'contained'} color={'default'} className={classes.buttonWithSpacing} onClick={() => setShowingDeviceStats(true)}>Tveskaeg statistics</Button>
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    control={<Switch size={"small"} checked={query.includeProcessed} onChange={(e) => {
                        query.includeProcessed = e.currentTarget.checked;
                        loadData();
                    }} />}
                    label="Include processed"
                />
                <FormControlLabel
                    control={<Switch size={"small"} checked={query.includeUnmeasuredSamples} onChange={(e) => {
                        query.includeUnmeasuredSamples = e.currentTarget.checked;
                        loadData();
                    }} />}
                    label="Include unmeasured samples"
                />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.sampleList}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox 
                                    checked={isAllSelected()}
                                    onClick={(e) => isAllSelected() ? setSelectedSamples([]) : setSelectedSamples(samples?.map(s => s.id)  || [])}
                                />                                
                            </TableCell>
                            <TableCell>Sample ID</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell align="right" className={classes.rightBorder}>#</TableCell>
                            { chemicals.map((c, idx) => 
                            <React.Fragment key={idx}>
                                <TableCell align="right">Avg {c} (mg/l)</TableCell>
                                <TableCell align="right" className={classes.rightBorder}>Std {c}</TableCell>
                            </React.Fragment>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {samples?.map((s, idx) => <React.Fragment key={s.id}>
                            <TableRow                                 
                                hover
                                onClick={(e: any) => props.history.push(`/sample/view/${selectedCompany}/${s.id}`)}
                                className={s.validity === 'OK' ? classes.okRow : classes.normalRow}
                                >
                                <TableCell padding="checkbox">
                                    <Checkbox 
                                        checked={selectedSamples.includes(s.id)}
                                        onClick={(e) => {
                                            if(selectedSamples.includes(s.id)) {
                                                selectedSamples.splice(selectedSamples.indexOf(s.id), 1);
                                                setSelectedSamples([...selectedSamples]); 
                                            } else {
                                                setSelectedSamples([...selectedSamples, s.id]); 
                                            }
                                            e.stopPropagation(); 
                                        }}
                                    />
                                </TableCell>
                                <TableCell>{s.sampleId}</TableCell>
                                <TableCell>{df(s.createdDate?.toString())}</TableCell>
                                <TableCell className={classes.rightBorder} align="right">{s.numberOfMeasurements}</TableCell>
                                {chemicals.map((c, idx2) =>
                                    <React.Fragment key={idx2}>
                                        <TableCell align="right">{vf(fc(s, c)?.avg)}</TableCell>
                                        <TableCell align="right" className={classes.rightBorder}>{vf(fc(s, c)?.std)}</TableCell>
                                    </React.Fragment>)}

                            </TableRow>
                        </React.Fragment>)}
                    </TableBody>
                </Table>
            </div>
            </Grid>
        </Grid>
        {renderDeviceStatistics()}
    </React.Fragment>);
}

export const SampleList = withRouter(withStyles(styles)(SampleListFunc));