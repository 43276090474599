import { WebRequestExecutor } from "./ServerFacade";
import { CompanyInformation, DeviceInformation } from "./ServerFacadeDevs";

export interface ServerFacadeAuth {
    authenticate(user: string, password: string, success: (auth: WebAuthenticationResponse) => void, fail: (reason: string, status?: number) => void): void;
    verifyToken(token: string, success: (auth: WebAuthenticationResponse) => void, fail: (reason: string, status?: number) => void): void;
}

export default class ServerFacadeAuthImpl implements ServerFacadeAuth {
    private readonly webRequestExecutor: WebRequestExecutor;

    public constructor(webRequestExecutor: WebRequestExecutor) {
        this.webRequestExecutor = webRequestExecutor;
    }

    // ----------------------------------------- ServerFacadeAuth Methods -----------------------------------------

    public authenticate(user: string, password: string, success: (auth: WebAuthenticationResponse) => void, fail: (reason: string, status?: number) => void) {
        this.webRequestExecutor.execute('/api/authentication/authenticate', null,
            success, fail,
            {
                userName: user,
                password: password
            },
            'POST'
        );
    }

    public verifyToken(token: string, success: (auth: WebAuthenticationResponse) => void, fail: (reason: string, status?: number) => void) {
        this.webRequestExecutor.execute('/api/authentication/hello', null,
            success, fail,
            null, 'GET', token
        );
    }
}

export interface WebAuthenticationResponse {
    authenticationToken: string;
    username: string;
    authenticatedDevices: DeviceInformation[];
    authenticatedCompanies: CompanyInformation[];
}
