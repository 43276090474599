import React from "react";
import ApplicationState from "../ApplicationState";
import { observer } from "mobx-react";
import { AbstractLoadingComponent } from "./AbstractLoadingComponent";
import { WithStyles, withStyles, Grid, Tooltip } from "@material-ui/core";
import { styles } from "../Styles";
import { observable } from "mobx";
import { DataTableComponent } from "./DataTableComponent";
import { DataTable } from "../Services/ServerFacadeStats";
import { Check, Warning, Error, DeviceUnknown } from "@material-ui/icons";

export interface DashboardHomeProps {
    appState: ApplicationState;
    machineSelected: (id: string) => void;
}

@observer
class DashboardHomeImpl extends AbstractLoadingComponent<DashboardHomeProps & WithStyles<typeof styles>, any> {

    @observable
    private data?: DataTable;

    public componentDidMount() {
        if (this.data === undefined) {
            this.loadData();
        }
    }

    doRender() {
        if (this.data === undefined) {
            return (<div />);
        }

        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item>
                        <DataTableComponent 
                            data={this.data} 
                            onClickHandler={(row) => {
                                let friendlyName = row[0];
                                let dev = this.props.appState.authenticatedDevices?.find(dev => dev.friendlyName === friendlyName);
                                if(dev) {
                                    this.props.machineSelected(dev.fpgaDna);
                                }
                            }}
                            dataFormatter={(column: number, val: any) => {
                                if(column === 1) {
                                    let desc = undefined;
                                    if(val?.includes(' - ')) {
                                        desc = val.substring(val.indexOf(' - ') + 3);
                                    }
                                    if(val === 'OK') {
                                        return <Tooltip title={desc || "Device OK"} arrow placement={'top-start'}><Check style={{ color: 'green' }} /></Tooltip>;
                                    } else if(val.startsWith('Warning')) {
                                        return <Tooltip title={desc || "Device has warnings!"} arrow placement={'top-start'}><Warning style={{ color: '#ecad17' }} /></Tooltip>;
                                    } else if(val.startsWith('Error')) {
                                        return <Tooltip title={desc || "Device has erros!"} arrow placement={'top-start'}><Error style={{ color: 'red' }} /></Tooltip>;
                                    } else {
                                        return <Tooltip title={desc || "Unable to find status for device"} arrow placement={'top-start'}><DeviceUnknown style={{ color: '#ecad17' }} /></Tooltip>;
                                    }
                                } else {
                                    return <React.Fragment>{val}</React.Fragment>;
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }


    private processData(data: DataTable) {
        let fpgadnaToFriendlyNameMap = this.props.appState.authenticatedDevices!.reduce((map, dev) => { map.set(dev.fpgaDna, dev.friendlyName || 'Unknown'); return map;}, new Map<string, string>());
        data.rows.forEach(r => r[0] = fpgadnaToFriendlyNameMap.get(r[0]) || r[0]);
    }

    private loadData() {
        this.loading = true;
        this.props.appState.serverFacade.statistics().periodicUsageStatistics(
            (data) => {
                this.processData(data);
                this.data = data;
                if (data.note) {
                    this.props.appState.snackbarMessage = data.note;
                }
                this.loading = false;
            },
            (reason) => {
                this.props.appState.snackbarMessage = 'Unable to load device usage statistics: ' + reason;
                this.loading = false;
            }
        );
    }
}

export const DashboardHome = withStyles(styles)(DashboardHomeImpl);

