import { observer } from "mobx-react";
import { action } from "mobx";
import { Link, withStyles, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import { styles } from "../../Styles";
import React from "react";
import { AbstractPopoverComponent } from "./AbstractPopoverComponent";

export interface FilterSelectorProps {
    typeName: string;
    typeNameSingular: string;
    allValues: string[];
    availableValues: string[];
    selectedValues: string[];
    numericValues?: boolean;
    valueChanged: (selectedValues: string[]) => void;
}

@observer
class FilterSelectorImpl extends AbstractPopoverComponent<FilterSelectorProps> {

    protected renderDescription(): string {
        var desc = '';
        if (!this.props.selectedValues || this.props.selectedValues.length === 0 || this.props.allValues.every(v => this.props.selectedValues.includes(v))) {
            desc = 'All ' + this.props.typeName;
        } else if (this.props.selectedValues.length === 1) {
            desc = this.props.selectedValues[0];
        } else {
            desc = this.props.selectedValues.length + ' ' + (this.props.selectedValues.length === 1 ? this.props.typeNameSingular : this.props.typeName);
        }
        return desc;
    }

    protected renderPopoverContent(): React.ReactNode {
        let classes = this.props.classes;
        let allValues = this.props.allValues!;
        let availableValues = this.props.availableValues || [];
        let selectedValues = this.props.selectedValues || [];
        if(this.props.numericValues) {
            allValues = allValues.sort((a, b) => {
                try {
                    const an = Number.parseInt(a);
                    const bn = Number.parseInt(b);
                    return an-bn;
                } catch {
                    return a.localeCompare(b, undefined, {sensitivity: 'base'});
                }
            });
        } else {
            allValues = allValues.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}));
        }
        return (
            <Grid container className={classes.selectionContainer}>
                {allValues.map((val, idx) =>
                    <Grid item key={idx}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedValues.includes(val)}
                                disabled={!availableValues.includes(val)}
                                onChange={(e) => this.handleCheck(e.target.checked, val) }
                                name={val.toString()}
                                color="primary"
                            />
                        }
                        label={val}
                    />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Link className={classes.button} component="button" variant="inherit" color="inherit" onClick={() => this.selectAll() }>Select all</Link>
                    <Link className={classes.button} component="button" variant="inherit" color="inherit" onClick={() => this.selectNone()}>Select none</Link>
                </Grid>
            </Grid>
        );
    }

    @action
    private handleCheck(checked: boolean, val: string) {
        this.closePopover();
        let selectedValues = Array.from(this.props.selectedValues);
        if (checked) {
            !selectedValues.includes(val) && selectedValues.push(val);
        } else {
            selectedValues.includes(val) && selectedValues.splice(selectedValues.indexOf(val), 1);
        }
        this.props.valueChanged(selectedValues);
    }

    @action
    private selectAll() {
        this.closePopover();
        this.props.valueChanged(this.props.allValues);
    }

    @action
    private selectNone() {
        this.closePopover();
        this.props.valueChanged([]);
    }
}
export const FilterSelector = withStyles(styles)(FilterSelectorImpl);
