import React, { Component, ErrorInfo } from 'react';
import ApplicationState from './ApplicationState';
import { observer } from 'mobx-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import CloseIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { Login } from './Components/Login';
import { Dashboard } from './Components/Dashboard';
import { Snackbar, IconButton, CircularProgress, ThemeProvider } from '@material-ui/core';
import { observable } from 'mobx';

@observer
export default class App extends Component {
    private appState: ApplicationState = new ApplicationState();

    @observable
    private intializing = true;

    public componentDidMount() {
        this.appState.initialize(() => this.intializing = false);
    }

    public render() {
        return (
            <ThemeProvider theme={this.appState.theme}>
                <React.Fragment>
                    <ErrorHandler>
                        <CssBaseline />
                        {this.renderComponents()}
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={this.appState.snackbarMessage !== undefined}
                            autoHideDuration={6000}
                            onClose={() => this.appState.snackbarMessage = undefined}
                            action={(
                                <IconButton key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={() => this.appState.snackbarMessage = undefined}>
                                    <CloseIcon />
                                </IconButton>)}
                            message={(<span>{this.appState.snackbarMessage}</span>)}
                            />
                    </ErrorHandler>
                </React.Fragment>
            </ThemeProvider>
            );
    }

    private renderComponents() {
        if (this.intializing) {
            return (
                <React.Fragment>
                    <div style={{ width: "400px", height: "400px", position:"absolute", top: 0, bottom: 0, left: 0, right: 0, margin: "auto" }}>
                        <CircularProgress size={400} />
                    </div>
                </React.Fragment>
            );
        } else if (this.appState.username == null) {
            return (<Login appState={this.appState} />);
        } else {
            return (<Dashboard appState={this.appState} />);
        }
    }
}

@observer
class ErrorHandler extends Component {

    @observable
    private hasError: boolean = false;
    private error?: Error;
    private errorInfo?: ErrorInfo;

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        this.error = error;
        this.errorInfo = errorInfo;
        this.hasError = true;
    }

    public render() {
        if (!this.hasError) {
            return this.props.children;
        }

        return (
            <div>
                <h2>Something went wrong.</h2>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                    {this.error && this.error.toString()}
                    <br />
                    {this.errorInfo && this.errorInfo.componentStack}
                </details>
            </div>
        );
    }
}