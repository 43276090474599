import { observable } from "mobx";
import { Component, ReactNode } from "react";
import { WithStyles, Popover, Button } from "@material-ui/core";
import { styles } from "../../Styles";
import React from "react";

export abstract class AbstractPopoverComponent<T> extends Component<T & WithStyles<typeof styles>, any> {
    @observable
    private popoverAnchorEl?: HTMLButtonElement | null;

    protected openPopover(target: HTMLButtonElement | null) {
        this.popoverAnchorEl = target;
    }

    protected closePopover() {
        this.popoverAnchorEl = undefined;
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderDescriptionComponent()}
                <Popover
                    open={this.popoverAnchorEl !== undefined}
                    anchorEl={this.popoverAnchorEl}
                    onClose={() => this.popoverAnchorEl = undefined}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {this.renderPopoverContent()}
                </Popover>
            </React.Fragment>
        );
    }

    protected renderDescriptionComponent(): React.ReactNode {
        return (<Button variant="outlined" onClick={(e) => this.openPopover(e.currentTarget)}>{this.renderDescription()}</Button>);
    }

    protected abstract renderDescription(): string;
    protected abstract renderPopoverContent(): ReactNode;

}
