import { ReactNode } from "react";
import { observable } from "mobx";
import React from "react";
import { LinearProgress } from "@material-ui/core";

export abstract class AbstractLoadingComponent<P, S> extends React.Component<P, S> {

    @observable
    protected loading = false;

    public render() {
        if (this.loading) {
            return (
                <div>
                    <LinearProgress variant="query" />
                </div>
            );
        } else {
            return this.doRender();
        }
    }


    protected abstract doRender(): ReactNode;  

    // ----- resize handling -----
    private timer: NodeJS.Timeout | null = null;
    private internalCallback = () => this.handleResizeEvent();
    private resizeCallback?: () => void;

    protected listenOnResize(callback: () => void): void {
        this.resizeCallback = callback;
        window.addEventListener('resize', this.internalCallback);
    }
    protected stopListenOnResize(): void {
        window.removeEventListener('resize', this.internalCallback);
    }

    private handleResizeEvent(): void {
        if (this.timer)
            clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.timer = null;
            this.resizeCallback!();
        }, 100);
    }
}

export const LoadingComponent = (props: any) => {
    return props.loading ? <LinearProgress variant="query" /> : props.children;
}