import { CircularProgress, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ApplicationState from "../../ApplicationState";
import { DeviceStatisticsResult, StatisticsTimespan } from "../../Services/ServerFacadeDevs";
import DeviceStatisticsView from "./DeviceStatisticsView";

const DeviceStatisticsControllerImpl = (props: {appState: ApplicationState, selectedCompany: number}) => {
    const [result, setResult] = useState<DeviceStatisticsResult | undefined>(undefined);
    const [period, setPeriod] = useState(StatisticsTimespan.WEEK);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(result)
            return;
        const df = props.appState.serverFacade.devices();
        df.getDeviceStatistics(
            props.selectedCompany
            , period
            , (res) => {
                setResult(res);
                setLoading(false);
            }
            , (reason) => {
                props.appState.snackbarMessage = 'Unable to load device statistics: ' + reason;    
            })
    });

    if(loading)
    {
        return <DeviceStatisticsLoading />;
    }

    return <DeviceStatisticsView
        period={period}
        periodChangeCallback={(newPeriod) => {
            setPeriod(newPeriod);
            setLoading(true);
            setResult(undefined);
        }}
        stats={result!}
    />;
};

export default observer(DeviceStatisticsControllerImpl);

const useStyles = makeStyles({
    container: {
      padding: '12px',
      margin: '12px',
    },
    gridCell: {
        textAlign: 'center',
    },
  });
  const DeviceStatisticsLoading = (props: {}) => {
    const classes = useStyles();
    return <>
        <Paper className={classes.container}>
            <Grid container direction={'column'}>
                <Grid item className={classes.gridCell}>
                    <CircularProgress />
                </Grid>
                <Grid item className={classes.gridCell}>
                    <Typography>Calculating<br />statistics...</Typography>
                </Grid>
            </Grid>
        </Paper>
    </>;
}
