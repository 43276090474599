import React from "react";
import { observer } from "mobx-react";
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, WithStyles, withStyles } from "@material-ui/core";
import { styles } from "../../Styles";
import ApplicationState from "../../ApplicationState";
import { AbstractLoadingComponent } from "../AbstractLoadingComponent";
import { observable } from "mobx";
import { CategoryDefinitionInfo } from "../../Services/ServerFacadeCategoryDefinitionsManager";
import { sortList } from "../../Utils";
import { Link } from 'react-router-dom';
import { AccountTreeRounded } from "@material-ui/icons";

export interface CategoryDefinitionListProps {
    appState: ApplicationState;
}

@observer
class CategoryDefinitionListImpl extends AbstractLoadingComponent<CategoryDefinitionListProps & WithStyles<typeof styles>, any> {

    @observable
    private categoryDefinitions?: CategoryDefinitionInfo[];

    @observable
    private selectedCompany: number = -1;

    public componentDidMount() {
        if(this.props.appState.authenticatedCompanies && this.props.appState.authenticatedCompanies.length > 0) {
            this.selectedCompany = this.props.appState.authenticatedCompanies[0].id;
        }
        if (this.categoryDefinitions === undefined) {
            this.loadData();
        }
    }

    public doRender() {
        const companies = this.props.appState.authenticatedCompanies!;
        return (
            <React.Fragment>
                <Select value={this.selectedCompany} onChange={(e) => this.selectNewCompany(e.target.value as string)}>
                    { companies.map((c, idx) => <MenuItem key={c.id} value={c.id}>{c.companyName}</MenuItem>) }
                </Select>
                { this.categoryDefinitions && this.listCategoryDefinitions(this.categoryDefinitions) }
            </React.Fragment>
        );

    }

    private selectNewCompany(company?: string) {
        this.selectedCompany = Number.parseInt(company || '-1');
        this.loadData();
    }

    private listCategoryDefinitions(categoryDefinitions: CategoryDefinitionInfo[]) {
        return (
            <React.Fragment>
                <Grid container spacing={3} direction={'column'}>
                    <Grid item>
                        <List>
                            {categoryDefinitions.map((cd, idx) => 
                            
                                <ListItem component={({innerRef,...props}) => <Link {...props} to={`/catdef/${this.selectedCompany}/edit/${cd.id}`}/>}>
                                    <ListItemIcon>
                                        <AccountTreeRounded />
                                    </ListItemIcon>
                                    <ListItemText primary={cd.label}/>
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                    <Grid item>
                        <Button variant={'contained'} color={'primary'} component={({innerRef,...props}) => <Link {...props} to={`/catdef/${this.selectedCompany}/new`}/>}>New</Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    private loadData() {
        if(this.selectedCompany === -1) {
            this.categoryDefinitions = undefined;
            return;
        }

        this.loading = true;
        this.props.appState.serverFacade.categoryDefinitionsManager().listCategoryDefinitions(
            this.selectedCompany,
            (categoryDefinitions) => {
                this.categoryDefinitions = sortList(categoryDefinitions, 'label');
                this.loading = false;
            },
            (reason) => {
                this.props.appState.snackbarMessage = 'Unable to load category definitions: ' + reason;
                this.loading = false;
            }
        );
    }
}

export const CategoryDefinitionList = withStyles(styles)(CategoryDefinitionListImpl);
