import { createStyles, FormControl, FormHelperText, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Grid, makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import React from "react";
import { DeviceStatisticsResult, StatisticsTimespan } from "../../Services/ServerFacadeDevs";
import { dateToString, numberToString } from "../../Utils";

const useStyles = makeStyles((theme) => 
    createStyles({
        container: {
        padding: '12px',
        margin: '12px',
        minHeight: '500px',
        minWidth: '800px',
        maxWidth: '1024px',
        },
        formControl: {
            //margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        gridContainer: {
            paddingTop: '20px',
        },
        grid: {

        },
        rightBorder: {
            borderRight: '1px solid',
        },
  }));

const resultToDataRows = (stats: DeviceStatisticsResult): any[] => {
    return stats.deviceStatistics.map(s => {
        const ret = {
            id: s.device,
            name: s.name,
            numberOfMeasurements: s.numberOfMeasurements
        };
        s.chemicals.forEach(c => {
            ret[c.name+'_avg'] = Math.trunc(c.avg);
            ret[c.name+'_std'] = Math.trunc(c.std);
        });
        return ret;
    });
}

const DeviceStatisticsView = (props: {
    stats: DeviceStatisticsResult,
    period: StatisticsTimespan,
    periodChangeCallback: (newPeriod: StatisticsTimespan) => void
}) => {
    const classes = useStyles();

    var chemicalNames = props.stats.deviceStatistics.flatMap(s => s.chemicals).map(c => c.name);
    chemicalNames = Array.from(new Set(chemicalNames));
    const rows = resultToDataRows(props.stats);

    return <> 
        <Paper className={classes.container}>
            <Grid container direction={'column'} spacing={2}>
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink id="period-label">
                        Include measurements from the last
                        </InputLabel>
                        <Select
                            labelId="period-label"
                            id="period"
                            value={props.period.valueOf()} 
                            onChange={(e) => props.periodChangeCallback(e.target.value as number)}
                            displayEmpty
                            className={classes.selectEmpty}
                        >
                            <MenuItem value={StatisticsTimespan.DAY}>Day</MenuItem>
                            <MenuItem value={StatisticsTimespan.WEEK}>Week</MenuItem>
                            <MenuItem value={StatisticsTimespan.MONTH}>Month</MenuItem>
                        </Select>
                        <FormHelperText>Select the period for which to include data in the device statistics</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item>
                    First measurement in the period is from {dateToString(props.stats.firstMeasurementDate)} and the last is from {dateToString(props.stats.lastMeasurementDate)}.
                </Grid>

                <Grid item className={classes.gridContainer}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Device</TableCell>
                                <TableCell align="right" className={classes.rightBorder}>#</TableCell>
                                { chemicalNames.map((c, idx) => 
                                <React.Fragment key={idx}>
                                    <TableCell align="right">Avg {c}</TableCell>
                                    <TableCell align="right" className={classes.rightBorder}>Std {c}</TableCell>
                                </React.Fragment>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, idx) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right" className={classes.rightBorder}>{row.numberOfMeasurements}</TableCell>
                                    { chemicalNames.map((c, idx) => 
                                    <React.Fragment key={idx}>
                                        <TableCell align="right">{numberToString(row[c+'_avg'])}</TableCell>
                                        <TableCell align="right" className={classes.rightBorder}>{numberToString(row[c+'_std'])}</TableCell>
                                    </React.Fragment>)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Paper>
    </>;
}

export default DeviceStatisticsView;
