import { WebRequestExecutor } from "./ServerFacade";

export interface CategoryDefinitionsManager {
    listCategoryDefinitions(company: number, success: (result: CategoryDefinitionInfo[]) => void, fail: (reason: string, status?: number) => void): void;

    getCategoryDefinition(company: number, id: string, success: (result: CategoryDefinition) => void, fail: (reason: string, status?: number) => void): void;

    saveCategoryDefinition(company: number, categoryDefinition: CategoryDefinition, success: () => void, fail: (reason: string, status?: number) => void): void;
    deleteCategoryDefinition(company: number, categoryDefinitionId: number, success: () => void, fail: (reason: string, status?: number) => void): void;
}

export default class CategoryDefinitionsManagerImpl implements CategoryDefinitionsManager {
    private readonly webRequestExecutor: WebRequestExecutor;

    public constructor(webRequestExecutor: WebRequestExecutor) {
        this.webRequestExecutor = webRequestExecutor;
    }

    // ----------------------------------------- ServerFacadeReferenceManager Methods -----------------------------------------
    
    listCategoryDefinitions(company: number, success: (result: CategoryDefinitionInfo[]) => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute(`/api/catdef/${company}`, null, success, fail);
    }

    getCategoryDefinition(company: number, id: string, success: (result: CategoryDefinition) => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute(
            `/api/catdef/${company}/${id}`,
            null, 
            (cd) => {
                cd.selections.forEach((s: CategorySelection) => { if(s.metadata === undefined || s.metadata === null) s.metadata = {}; });
                success(cd);
            }, 
            fail);
    }

    saveCategoryDefinition(company: number, categoryDefinition: CategoryDefinition, success: () => void, fail: (reason: string, status?: number) => void): void {
        if(categoryDefinition.id === -1)
            this.webRequestExecutor.execute(`/api/catdef/${company}`, null, success, fail, categoryDefinition, 'POST');
        else
            this.webRequestExecutor.execute(`/api/catdef/${company}/${categoryDefinition.id}`, null, success, fail, categoryDefinition, 'PUT');
    }

    deleteCategoryDefinition(company: number, categoryDefinitionId: number, success: () => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute(`/api/catdef/${company}/${categoryDefinitionId}`, null, success, fail, null, 'DELETE');
    }

    // ----------------------------------------- Private parts -----------------------------------------

}

// ----------------------------------------- Model -----------------------------------------

export interface CategoryDefinitionInfo {
    id: number;
    label: string;
}

export interface CategoryDefinition {
    id: number;
    label: string;

    categories: Category[];
    selections: CategorySelection[];
}

export interface Category {
    label: string;
    values: CategoryValue[];
}
export const CATEGORY_VALUE_ALL: string = '_ALL_';

export interface CategoryValue {
    id: string;
    label: string;
}
export interface CategorySelection {
    categories: string[];
    metadata: any;
}