import React from "react";
import { observer } from "mobx-react";
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, List, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import { styles } from "../../Styles";
import ApplicationState from "../../ApplicationState";
import { AbstractLoadingComponent } from "../AbstractLoadingComponent";
import { action, observable } from "mobx";
import { Category, CategoryDefinition, CategorySelection, CategoryValue, CATEGORY_VALUE_ALL } from "../../Services/ServerFacadeCategoryDefinitionsManager";
import { AddCircle, Delete, RemoveCircle } from "@material-ui/icons";
import { CategoryValueListItem } from "./CategoryValueListItem";
import { CategoryValueEditor } from "./CategoryValueEditor";
import { RouteComponentProps, withRouter } from "react-router-dom";

export interface CategoryDefinitionEditorProps {
    appState: ApplicationState;
    company: number;
    editId?: string;
}

@observer
class CategoryDefinitionEditorImpl extends AbstractLoadingComponent<CategoryDefinitionEditorProps & WithStyles<typeof styles> & RouteComponentProps, any> {
    
    private title: string = '';

    @observable
    private categoryDefinition?: CategoryDefinition;

    @observable
    private newCategoryValue?: CategoryValue;
    @observable
    private newCategoryValueTarget?: Category;

    @observable
    private deleteDialogOpen = false;

    public componentDidMount() {
        if (this.props.editId && this.categoryDefinition === undefined) {
            this.loadData();
        } else if(!this.props.editId) {
            this.title = 'New category definition';
            this.categoryDefinition = {
                id: -1, 
                label: '',
                categories: [],
                selections: []
            };
        }
    }
    
    public doRender() {
        const classes = this.props.classes;
        if(!this.categoryDefinition)
            return (<React.Fragment />);
        const cd = this.categoryDefinition;

        return (
            <React.Fragment>
                <Container className={classes.dashpaper}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <p>{ this.title }</p>
                        </Grid>
                        <Grid item xs={3}>
                            <Button className={classes.buttonWithSpacing} variant={'contained'}  onClick={() => this.props.history.push('/catdef/list')}>Cancel</Button>
                            <Button className={classes.buttonWithSpacing} variant={'contained'} color={'secondary'} disabled={this.props.editId === undefined} onClick={() => this.deleteDialogOpen = true}>Delete</Button>
                            <Button className={classes.buttonWithSpacing} variant={'contained'} color={'primary'} disabled={!this.isValid()} onClick={() => this.save()}>Save</Button>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField label="Label" variant="outlined" value={cd.label} onChange={(e) => cd.label = e.target.value} error={this.isDefinitionLableInvalid()}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            { this.renderSelections(cd) }
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            { this.renderCategories(cd) }
                        </Grid>
                    </Grid>
                </Container>
                <Dialog
                    open={this.deleteDialogOpen}
                    onClose={() => this.deleteDialogOpen = false}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Delete category definition</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you really want to delete category definition {this.categoryDefinition!.label}? This operation CAN NOT be undone!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.delete()} color="primary">Delete</Button>
                        <Button onClick={() => this.deleteDialogOpen = false} color="primary" autoFocus>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    private save() {
        this.loading = true;
        this.props.appState.serverFacade.categoryDefinitionsManager().saveCategoryDefinition(
            this.props.company,
            this.categoryDefinition!, 
            () => {
                this.props.history.push('/catdef/list');
            },
            (error) => {
                this.loading = false;
                this.props.appState.snackbarMessage = error;
            })
    }

    private delete() {
        this.loading = true;
        this.props.appState.serverFacade.categoryDefinitionsManager().deleteCategoryDefinition(
            this.props.company,
            this.categoryDefinition!.id, 
            () => {
                this.props.history.push('/catdef/list');
            },
            (error) => {
                this.loading = false;
                this.props.appState.snackbarMessage = error;
            })

    }

    // -------------------------------------------- Categories --------------------------------------------

    private renderCategories(cd: CategoryDefinition) {
        const classes = this.props.classes;
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AddCircle onClick={() => this.addCategori()} />
                        <Typography className={classes.upperText} component={'span'}>{ '' + cd.categories.length + ' categories' }</Typography>
                        <RemoveCircle onClick={() => this.removeCategori()} />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {cd.categories.map((c, idx) => this.renderCategory(idx, c))}
                        </Grid>
                    </Grid>
                </Grid>
                { this.newCategoryValue && this.newCategoryValueTarget &&
                    <CategoryValueEditor 
                        categoryValue={this.newCategoryValue}
                        takenIds={this.newCategoryValueTarget.values.map(cv => cv.id)}
                        cancelCallback={() => this.newCategoryValue = undefined}
                        saveCallback={() => this.saveNewCategoryValue()}
                    />
                }
            </React.Fragment>
        );
    }

    @action
    private addCategori() {
        this.categoryDefinition!.categories.push({
            label: '',
            values: []
        });
        this.categoryDefinition!.selections.forEach(s => {
            s.categories.push(CATEGORY_VALUE_ALL);
        });
    }
    
    @action
    private removeCategori() {
        const categories = this.categoryDefinition!.categories;
        categories.splice(categories.length - 1, 1);
        this.categoryDefinition!.selections.forEach(s => {
            s.categories.splice(s.categories.length - 1, 1);
        });
    }

    private renderCategory(idx: number, c: Category) {
        return  (
            <React.Fragment key={idx}>
                <Grid item xs={this.calculateCategoryWidth(c)} key={idx}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        Category {idx+1}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Label" variant="outlined" value={c.label} onChange={(e) => c.label = e.target.value} error={this.isCategoryLableInvalid(c)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {c.values.map((cv, idx) => <CategoryValueListItem key={idx} category={c} categoryValue={cv} />)}
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={'contained'} onClick={() => this.openCategoryValueEditor(c)}>New {c.label}</Button>
                    </Grid>
                </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    private calculateCategoryWidth(c: Category): 3 | 6 | 12 {
        let maxLength = 0;
        c.values.forEach(cv => { if(maxLength < cv.label.length) maxLength = cv.label.length; });
        if(maxLength < 20) {
            return 3;
        } else if(maxLength < 70) {
            return 6;
        } else {
            return 12;
        }
    }

    @action
    private openCategoryValueEditor(c: Category) {
        this.newCategoryValue = {
            id: '',
            label: ''
        };
        this.newCategoryValueTarget = c;
    }

    @action
    private saveNewCategoryValue() {
        if(!this.newCategoryValue)
            return;
        this.newCategoryValueTarget?.values.push(this.newCategoryValue);
        this.newCategoryValue = undefined;
        this.newCategoryValueTarget = undefined;
    }

    // -------------------------------------------- Selections --------------------------------------------

    private renderSelections(cd: CategoryDefinition) {
        const classes = this.props.classes;
        const categories = this.categoryDefinition!.categories;
        const selections = this.categoryDefinition!.selections;

        const categorySelections = categories.map(c => c.values.map(cv => <MenuItem key={cv.id} value={cv.id}>{cv.label}</MenuItem>));
        categorySelections.forEach(csArray => csArray.unshift(<MenuItem key={CATEGORY_VALUE_ALL} value={CATEGORY_VALUE_ALL}>All values</MenuItem>));
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AddCircle onClick={() => this.addSelection()} />
                        <Typography className={classes.upperText} component={'span'}>{ '' + cd.selections.length + ' selections' }</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                            <TableRow>
                                {categories.map((c, idx) => <TableCell key={idx}>{c.label}</TableCell>)}
                                <TableCell>Metadata</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {selections.map((s, idx) => (
                                <TableRow key={idx}>
                                    {categorySelections.map((cs, idx) => 
                                        <TableCell scope="row" key={idx}>
                                            <Select key={idx} value={s.categories[idx]} onChange={(e) => s.categories[idx] = e.target.value as string}>
                                                {categorySelections[idx]}
                                            </Select>
                                        </TableCell>
                                    )}
                                    <TableCell scope='row'>
                                        { this.renderMeataData(s) }
                                    </TableCell>
                                    <TableCell scope='row'>
                                        <IconButton edge="end" aria-label="delete" onClick={() => this.deleteSelection(s)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    private addSelection() {
        const categories: string[] = [];
        for(var i=0; i<this.categoryDefinition!.categories.length; i++) {
            categories.push(CATEGORY_VALUE_ALL);
        }
        this.categoryDefinition!.selections.push({
            categories: categories,
            metadata: {}
        });
    }

    private deleteSelection(s: CategorySelection) {
        const idx = this.categoryDefinition?.selections.indexOf(s);
        if(idx)
            this.categoryDefinition?.selections.splice(idx, 1);
    }

    @observable
    private metadataEditSelection?: CategorySelection;
    @observable
    private metadataEdit: any;

    private renderMeataData(s: CategorySelection) {
        const min = s.metadata.min || '?';
        const target = s.metadata.target || '?';
        const max = s.metadata.max || '?';


        const isMinInValid = !this.isValidNumber(this.metadataEdit?.min);
        const isTargetInValid = !this.isValidNumber(this.metadataEdit?.target);
        const isMaxInValid = !this.isValidNumber(this.metadataEdit?.max);
        return ( 
            <React.Fragment>
                <Button onClick={() => { this.metadataEditSelection = s; this.metadataEdit = {...s.metadata}; }}>{min} / {target} / {max}</Button>
                { this.metadataEditSelection !== undefined && 
                    <React.Fragment>
                        <Dialog open={true} onClose={() => this.metadataEditSelection = undefined}>
                            <DialogTitle>Metadata</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Provide metadata values for the selction
                                </DialogContentText>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField 
                                            label={'Minimum acceptable value'}
                                            value={this.metadataEdit.min}
                                            onChange={(e) => {
                                                const n = Number(e.target.value);
                                                if(!isNaN(n)) this.metadataEdit.min = n;
                                            }}
                                            margin={'dense'}
                                            error={isMinInValid}
                                            style={{minWidth: '300px'}}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            label={'Target value'}
                                            value={this.metadataEdit.target}
                                            onChange={(e) => {
                                                const n = Number(e.target.value);
                                                if(!isNaN(n)) this.metadataEdit.target = n;
                                            }}
                                            margin={'dense'}
                                            error={isTargetInValid}
                                            style={{minWidth: '300px'}}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            label={'Maximum acceptable value'}
                                            value={this.metadataEdit.max}
                                            onChange={(e) => {
                                                const n = Number(e.target.value);
                                                if(!isNaN(n)) this.metadataEdit.max = n;
                                            }}
                                            margin={'dense'}
                                            error={isMaxInValid}
                                            style={{minWidth: '300px'}}
                                            />
                                    </Grid>
                                </Grid> 
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.metadataEditSelection = undefined} color="default">Cancel</Button>
                                <Button 
                                    disabled={isMinInValid || isTargetInValid || isMaxInValid} 
                                    onClick={() => {this.metadataEditSelection!.metadata = this.metadataEdit; this.metadataEditSelection = undefined; }} 
                                    color="primary">
                                    Accept
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>}
            </React.Fragment>
        );
    }

    private isValidNumber(val?: any): boolean {
        if(val === undefined || val === null) {
            return true;
        }

        const n = Number(val);
        const ret = !(isNaN(n) || n === Infinity);
        return ret;
    }

    // -------------------------------------------- Internal --------------------------------------------


    private loadData() {
        if(!this.props.editId)
            return;
        this.loading = true;
        this.props.appState.serverFacade.categoryDefinitionsManager().getCategoryDefinition(
            this.props.company,
            this.props.editId,
            (categoryDefinition) => {
                this.title = 'Editing ' + categoryDefinition.label;
                this.categoryDefinition = categoryDefinition;
                this.loading = false;
            },
            (reason) => {
                this.props.appState.snackbarMessage = 'Unable to load category definition: ' + reason;
                this.loading = false;
            }
        );
    }

    // -------------------------------------------- Validation --------------------------------------------

    private isValid(): boolean {
        return !this.isDefinitionLableInvalid() && 
                !this.isCategoriesInvalid() &&
                !this.isSelectionsInvalid();
    }

    private isDefinitionLableInvalid(): boolean {
        return this.categoryDefinition?.label === undefined || this.categoryDefinition?.label.trim() === '';
    }

    // --------- Categories ---------

    private isCategoriesInvalid(): boolean {
        var ret = false;
        this.categoryDefinition?.categories.forEach((c) => { if(this.isCategoryInvalid(c)) ret = true; });
        return ret;
    }

    private isCategoryInvalid(c: Category): boolean {
        return this.isCategoryLableInvalid(c) || c.values.length === 0;
    }

    private isCategoryLableInvalid(c: Category): boolean {
        return c.label === undefined || c.label.trim() === '';
    }

    // --------- Selections ---------

    private isSelectionsInvalid(): boolean {
        var ret = false;
        var possibleValues: string[][] = [];
        this.categoryDefinition?.categories.forEach((c, idx) => possibleValues.push(c.values.map(v => v.id)));
        this.categoryDefinition?.selections.forEach((s) => { if(this.isSelectionInvalid(s, possibleValues)) ret = true; });
        return ret;
    }

    private isSelectionInvalid(s: CategorySelection, possibleValues: string[][]): boolean {
        var ret = false;
        if(s.categories.length !== this.categoryDefinition?.categories.length) {
            ret = true;
        }
        s.categories.forEach((sc, idx) => {
            if(sc !== CATEGORY_VALUE_ALL && !possibleValues[idx].includes(sc)) {
                ret = true;
            }
        });

        return ret;
    }
}

export const CategoryDefinitionEditor = withRouter(withStyles(styles)(CategoryDefinitionEditorImpl));
