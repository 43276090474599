import { DateRange } from "../DateRangePicker";
import { observer } from "mobx-react";
import { observable, runInAction } from "mobx";
import { TextField, Link, withStyles, Button, Grid } from "@material-ui/core";
import { styles } from "../../Styles";
import React from "react";
import moment from "moment-timezone";
import { AbstractPopoverComponent } from "./AbstractPopoverComponent";

const dateFormat: string = 'YYYY/MM/DD HH:mm:ss'

export interface DateRangeSelectorProps {
    initialDateRange: DateRange;
    valueChanged: (newValue: DateRange) => void;
}

@observer
class DateRangeSelectorImpl extends AbstractPopoverComponent<DateRangeSelectorProps> {
    @observable
    private editStartStr: string = "";

    @observable
    private editEndStr: string = "";

    protected openPopover(target: HTMLButtonElement | null) {
        super.openPopover(target);
        this.editStartStr = moment(this.props.initialDateRange.start).format(dateFormat);
        this.editEndStr = moment(this.props.initialDateRange.end).format(dateFormat);
    }

    protected renderDescriptionComponent(): React.ReactNode {
        return (
            <Button variant="contained" color="primary" onClick={(e) => this.openPopover(e.currentTarget)}>
                {this.renderDescription()}
            </Button>
        );
    }
    protected renderDescription(): string {
        return moment(this.props.initialDateRange.start).format(dateFormat) + ' - ' +  moment(this.props.initialDateRange.end).format(dateFormat);
    }

    protected renderPopoverContent(): React.ReactNode {
        let classes = this.props.classes;
        return (
            <Grid container className={classes.selectionContainer} spacing={2}>
                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField error={!this.isStartValid()} label="Start" value={this.editStartStr} onChange={(e) => this.editStartStr = e.target.value} />
                        </Grid>
                        <Grid item>
                            <TextField error={!this.isEndValid()} label="End" value={this.editEndStr} onChange={(e) => this.editEndStr = e.target.value} />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.signalValueChanged({ start: moment(this.editStartStr, dateFormat).toDate(), end: moment(this.editEndStr, dateFormat).toDate() })}
                                disabled={!this.isFormValid()}
                            >
                                Apply
                                    </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        {dateRangeSelections.map((dr, idx) => (
                            <Grid item key={idx}>
                                <Link className={classes.timeSelectionButton} component="button" variant="inherit" color="inherit" onClick={() => {
                                    runInAction(() => this.signalValueChanged({ start: dr.start().toDate(), end: dr.end().toDate() }))
                                }}>{dr.name}</Link>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        );
    }


    private isStartValid(): boolean {
        return moment(this.editStartStr, dateFormat, true).isValid();
    }
    private isEndValid(): boolean {
        let ret = moment(this.editEndStr, dateFormat, true).isValid();
        return ret;
    }
    private isFormValid(): boolean {
        return this.isStartValid() && this.isEndValid();
    }

    private signalValueChanged(dateRange: DateRange) {
        this.closePopover();
        this.props.valueChanged(dateRange);
    }
}
export const DateRangeSelector = withStyles(styles)(DateRangeSelectorImpl);

interface DateRangeSelection {
    name: string;
    start: () => moment.Moment;
    end: () => moment.Moment;
}

const dateRangeSelections: DateRangeSelection[] = [
    {
        name: "Last 30 minutes",
        start: () => moment().subtract(30, 'minutes'),
        end: () => moment()
    },
    {
        name: "Last 1 hour",
        start: () => moment().subtract(1, 'hour'),
        end: () => moment()
    },
    {
        name: "Last 6 hours",
        start: () => moment().subtract(6, 'hour'),
        end: () => moment()
    },
    {
        name: "Last 12 hours",
        start: () => moment().subtract(12, 'hour'),
        end: () => moment()
    },
    {
        name: "Last 24 hours",
        start: () => moment().subtract(24, 'hour'),
        end: () => moment()
    },
    {
        name: "Last 2 days",
        start: () => moment().subtract(2, 'days'),
        end: () => moment()
    },
    {
        name: "Last 7 days",
        start: () => moment().subtract(7, 'days'),
        end: () => moment()
    },
    {
        name: "Last 30 days",
        start: () => moment().subtract(30, 'days'),
        end: () => moment()
    },
    {
        name: "Last 90 days",
        start: () => moment().subtract(90, 'days'),
        end: () => moment()
    },
    {
        name: "Yesterday",
        start: () => moment().subtract(1, 'days').startOf('day'),
        end: () => moment().subtract(1, 'days').endOf('day')
    },
    {
        name: "Last week",
        start: () => moment().subtract(1, 'weeks').startOf('isoWeek'),
        end: () => moment().subtract(1, 'weeks').endOf('isoWeek')
    },
    {
        name: "Last month",
        start: () => moment().subtract(1, 'month').startOf('month'),
        end: () => moment().subtract(1, 'month').endOf('month')
    },
]
