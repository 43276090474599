import ApplicationState from "../../ApplicationState";
import { observer } from "mobx-react";
import { Component } from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { styles } from "../../Styles";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { CategoryDefinitionList } from "./CategoryDefinitionList";
import { CategoryDefinitionEditor } from "./CategoryDefinitionEditor";

interface MatchParams {
    company: string;
    id?: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
}

export interface CategoryDefinitionManagerProps {
    appState: ApplicationState;
}

@observer
class CategoryDefinitionManagerImpl extends Component<CategoryDefinitionManagerProps & WithStyles<typeof styles>, any> {
    public render() {
        return (
            <Switch>
                <Route path={'/catdef/list'} exact>
                    <CategoryDefinitionList appState={this.props.appState} />
                </Route>
                <Route path={'/catdef/:company/new'} exact render={( {match}: MatchProps) => (
                    <CategoryDefinitionEditor appState={this.props.appState} company={Number.parseInt(match.params.company)} /> )} />
                <Route path={'/catdef/:company/edit/:id'} exact render={( {match}: MatchProps) => (
                    <CategoryDefinitionEditor appState={this.props.appState} company={Number.parseInt(match.params.company)} editId={match.params.id}/> )} />
          </Switch>
        );
    }
}

export const CategoryDefinitionManager = withStyles(styles)(CategoryDefinitionManagerImpl);
