import { WebRequestExecutor } from "./ServerFacade";

export interface ServerFacadePreferences {
    getPreferences(success: (preferences: Preferences) => void, fail: (reason: string, status?: number) => void): void;
    updatePreferences(preferences: Preferences, success: (preferences: Preferences) => void, fail: (reason: string, status?: number) => void): void;
}

export default class ServerFacadePreferencesImpl implements ServerFacadePreferences {
    private readonly webRequestExecutor: WebRequestExecutor;

    public constructor(webRequestExecutor: WebRequestExecutor) {
        this.webRequestExecutor = webRequestExecutor;
    }

    // ----------------------------------------- ServerFacadePreferences Methods -----------------------------------------

    public getPreferences(success: (preferences: Preferences) => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute('/api/preferences', null, success, fail);
    }

    public updatePreferences(preferences: Preferences, success: (preferences: Preferences) => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute('/api/preferences'
            , null
            , success
            , fail
            , preferences
            , 'PUT'
        );
    }
}

export interface Preferences {
    convertNaToNaCl: boolean;
    usePercentages: boolean;
    flowMode: boolean;
    useDarkMode: boolean;
    tagParts: string[];
}
