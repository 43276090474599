import ApplicationState from "../ApplicationState";
import { observer } from "mobx-react";
import { AbstractLoadingComponent } from "./AbstractLoadingComponent";
import { WithStyles, withStyles, FormControl, FormGroup, Switch, FormControlLabel, CircularProgress, InputLabel, Select, MenuItem, TextField, Grid, Button } from "@material-ui/core";
import { styles } from "../Styles";
import React from "react";
import { observable } from "mobx";
import { Preferences } from '../Services/ServerFacadePreferences';

export interface PreferencesComponentProps {
    appState: ApplicationState;
}

@observer
class PreferencesComponentImpl extends AbstractLoadingComponent<PreferencesComponentProps & WithStyles<typeof styles>, any> {

    @observable
    private saving: boolean = false;

    @observable
    private preferences?: Preferences;

    public componentDidMount() {
        this.preferences = Object.assign({}, this.props.appState.preferences!);
    }

    public doRender() {
        if(!this.preferences) {
            return <React.Fragment />
        }
        
        return (
            <React.Fragment>
                {this.saving && <CircularProgress />}
                <Grid container spacing={2} direction={'column'}>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={this.preferences!.convertNaToNaCl} onChange={(e) => this.preferences!.convertNaToNaCl = e.target.checked}/>}
                                    label="Show NaCl instead of Na"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={this.preferences!.usePercentages} onChange={(e) => this.preferences!.usePercentages = e.target.checked}/>}
                                    label="Show percentages instead of ppm"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={this.preferences!.flowMode} onChange={(e) => this.preferences!.flowMode = e.target.checked}/>}
                                    label="Flow mode"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={this.preferences!.useDarkMode} onChange={(e) => this.preferences!.useDarkMode = e.target.checked}/>}
                                    label="Use dark mode"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Grid container direction={'column'} spacing={1}>
                            <Grid item>
                                <InputLabel id="tag-parts-label">Number of tag parts</InputLabel>
                                <Select
                                    labelId="tag-parts-label"
                                    id="tag-parts-select"
                                    value={this.preferences!.tagParts.length === 0 ? 1 : this.preferences!.tagParts.length}
                                    onChange={(e) => this.adjustTagLablesSize(parseInt(e.target.value as string))}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                </Select>
                            </Grid>
                            {this.props.appState.preferences?.tagParts.map((v, idx) =>
                                <Grid item key={idx} >
                                    <TextField
                                        error={!v}
                                        label={"Tag part " + (idx+1)} 
                                        value={v}
                                        onChange={e => this.preferences!.tagParts[idx] = e.target.value}/>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button variant={'contained'} color={'primary'} onClick={e => this.savePreferences()} disabled={!this.isValid()}>Save</Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    private adjustTagLablesSize(newSize: number) {
        if(newSize === 1) {
            this.preferences!.tagParts = [];
        } else {
            let tagparts = this.preferences!.tagParts;
            while(newSize > tagparts.length) {
                tagparts.push('');
            }
            while(newSize < tagparts.length) {
                tagparts.splice(tagparts.length - 1);
            }
        }
    }

    private isValid(): boolean {
        let tagparts = this.preferences!.tagParts;
        for(var i=0; i<tagparts.length; i++) {
            if(!tagparts[i])
                return false;
        }
        return true;
    }

    private savePreferences() {
        this.saving = true;
        this.props.appState.serverFacade.preferences().updatePreferences(
            this.preferences!,
            (prefs) => {
                this.props.appState.processNewPreferences(prefs);
                this.saving = false;
            },
            (reason) => {
                this.props.appState.snackbarMessage = 'Unable to save preferences: ' + reason;
                this.saving = false;
            }
        );
    }
}

export const PreferencesComponent = withStyles(styles)(PreferencesComponentImpl);
