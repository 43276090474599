import ApplicationState from "../ApplicationState";
import React from "react";
import { observable, action } from "mobx";
import {
    Avatar,
    Button,
    FormControl,
    Input,
    InputLabel,
    Paper,
    Typography,
    withStyles,
    WithStyles,

    Grid
} from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import { styles } from "../Styles";
import { AbstractLoadingComponent } from "./AbstractLoadingComponent";
import { observer } from "mobx-react";

export interface LoginProps {
    appState: ApplicationState;
}

@observer
class LoginImpl extends AbstractLoadingComponent<LoginProps & WithStyles<typeof styles>, any> {

    @observable
    private username: string = "";

    @observable
    private password: string = "";

    public doRender() {
        const classes = this.props.classes;
        return (
            <Grid container component="main" className={classes.login_root}>
                <Grid item xs={false} sm={4} md={7} className={classes.login_image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Paper className={classes.paper}>
                        <Avatar>
                            <LockOutlined />
                        </Avatar>
                        <Typography component="h1">Sign in</Typography>
                        <form>
                            <FormControl margin="normal" required={true} fullWidth={true}>
                                <InputLabel htmlFor="email">Email Address</InputLabel>
                                <Input id="username" name="Bruger" autoComplete="email" autoFocus={true} value={this.username} onChange={(e) => this.username = e.target.value} onKeyDown={(e) => this.onKeyPress(e)} />
                            </FormControl>
                            <FormControl margin="normal" required={true} fullWidth={true}>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <Input name="password" type="password" id="password" autoComplete="current-password" value={this.password} onChange={(e) => this.password = e.target.value} onKeyDown={(e) => this.onKeyPress(e)} />
                            </FormControl>
                            <Button
                                fullWidth={true}
                                variant="contained"
                                color="primary"
                                onClick={() => this.login()}
                            >
                                Sign in
                        </Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    private onKeyPress(e: any) {
        if (e.keyCode === 13) {
            this.login();
        }
    }

    @action
    private login() {
        this.loading = true;
        this.props.appState.serverFacade.authentication().authenticate(
            this.username!, this.password!
            , (auth) => {
                this.props.appState.processAuthenticationInformation(auth, () => this.loading = false);
            },
            (reason) => {
                this.props.appState.snackbarMessage = 'Login failed: ' + reason;
                this.loading = false;
            }
        );
    }

}

export const Login = withStyles(styles)(LoginImpl);