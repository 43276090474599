import React, { Component } from "react";
import { observer } from "mobx-react";
import { styles } from "../../Styles";
import { action, observable } from "mobx";
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, withStyles, WithStyles } from "@material-ui/core";
import { Category, CategoryValue } from "../../Services/ServerFacadeCategoryDefinitionsManager";
import { Delete } from "@material-ui/icons";
import { CategoryValueEditor } from "./CategoryValueEditor";

export interface CategoryValueListItemProps {
    category: Category;
    categoryValue: CategoryValue;
}

@observer
class CategoryValueListItemImpl extends Component<CategoryValueListItemProps & WithStyles<typeof styles>, any> {

    @observable
    private editValue: boolean = false;

    public render() {
        return (
            <React.Fragment>
                <ListItem button onClick={() => this.editValue = true}>
                    <ListItemText primary={this.props.categoryValue.label} secondary={this.props.categoryValue.id} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => this.deleteCategoryValue()}>
                        <Delete />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                { this.editValue &&
                    <CategoryValueEditor 
                        categoryValue={this.props.categoryValue}
                        takenIds={this.props.category.values.map(cv => cv.id)}
                        cancelCallback={() => this.editValue = false}
                        saveCallback={() => this.editValue = false}
                    />
                }
            </React.Fragment>
        );
    }

    @action
    private deleteCategoryValue() {
        const idx = this.props.category.values.indexOf(this.props.categoryValue);
        this.props.category.values.splice(idx, 1);
    }
}


export const CategoryValueListItem = withStyles(styles)(CategoryValueListItemImpl);