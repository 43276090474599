import React, { Component } from "react";
import ApplicationState from "../../ApplicationState";
import { observer } from "mobx-react";
import { WithStyles, withStyles, Divider, CircularProgress, Grid, FormControlLabel, Switch, Link } from "@material-ui/core";
import { styles } from "../../Styles";
import { observable, runInAction } from "mobx";
import moment from 'moment-timezone';
import { DateRange } from "../DateRangePicker";
import { DateRangeSelector } from "./DateRangeSelector";
import { FilterSelector } from "./FilterSelector";
import { ChartsComponent, DataRowValueExtractor, DataRowValueExtractorFactory } from "./ChartsComponent";
import { sortList } from "../../Utils";
import { GroupSplitSelector } from "./GroupSplitSelector";
import { GraphInfo, GraphsRequest, DataSerie, DataPoint } from "../../Services/ServerFacadeStats";
import { Preferences } from "../../Services/ServerFacadePreferences";
import { isThisTypeNode } from "typescript";

export interface StatisticsProps {
    appState: ApplicationState;
    queryValuesInitializer?: (qv: QueryValues) => void;
}

export interface QueryValues {
    dateRange: DateRange;
    devices: string[];
    jobs: string[];
    algorithms: string[];
    chemicals: string[];
    sampleTags: string[];
    sampleTagsParts: string[][];
    durations: number[];
    
    includeDiagnostics: boolean;

    splitByKeys: string[];
    dataSeriesKeys: string[];
}

@observer
class StatisticsImpl extends Component<StatisticsProps & WithStyles<typeof styles>, any> {

    @observable
    private loadingData: boolean = true;

    @observable
    private availableValues: any = {};

    @observable
    private limitedAvailableValues: any = {};

    @observable
    private showLegend = true;

    @observable
    private graphs?: GraphInfo[];

    @observable
    private dataUrl?: string;

    private startSubtract = this.props.appState.username?.toLowerCase() === "natureenergytruck1@nanonord.dk" ? 168 : 12;

    private queryValues: QueryValues = {
        dateRange: {
            start: moment().subtract(this.startSubtract, 'hour').toDate(),
            end: moment().toDate()
        },
        devices: [],
        jobs: [],
        algorithms: [],
        chemicals: [],
        sampleTags: [],
        sampleTagsParts: [],
        durations: [],

        includeDiagnostics: false,

        splitByKeys: ['device'],
        dataSeriesKeys: ['chemical']
    }
    private readonly tagParts: string[];
    private readonly useTagParts: boolean;
    private readonly selectableValues: Map<string, string> = new Map<string, string>();

    public constructor(props: StatisticsProps & WithStyles<typeof styles>) {
        super(props);

        // Initialize tags
        this.tagParts = this.props.appState.preferences!.tagParts;
        this.useTagParts = this.tagParts.length > 0;
        for(var i=0; i<this.tagParts.length; i++) {
            this.queryValues.sampleTagsParts.push([]);
        }
        console.log(this.props.appState.username);
        if (this.props.appState.username?.toLowerCase() === "natureenergytruck1@nanonord.dk") {
            this.startSubtract = 168;
            this.queryValues.dateRange.start = moment().subtract(this.startSubtract, 'hour').toDate();
        }

        // Initialize selectable values
        this.selectableValues.set('device', 'Device');
        this.selectableValues.set('job', 'Job');
        this.selectableValues.set('algorithm', 'Algorithm');
        this.selectableValues.set('duration', 'Duration');
        if(this.useTagParts) {
            for(i=0; i<this.tagParts.length; i++) {
                this.selectableValues.set('sampletag' + i, this.tagParts[i]);
            }
        } else {
            this.selectableValues.set('sampletag', 'Sampletag');
        }
        this.selectableValues.set('chemical', 'Chemical');

        // Initialize query values
        if(this.props.queryValuesInitializer) {
            this.props.queryValuesInitializer(this.queryValues);
        }

        // And load data
        this.initializeData();
    }

    public render() {
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    {this.renderFilters()}
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={12}>
                        {this.renderGraphs()}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    // ----------------------------- Filters -----------------------------

    private renderFilters() {
        let classes = this.props.classes;
        return (
            <React.Fragment>
                <Grid item>
                    <DateRangeSelector initialDateRange={this.queryValues.dateRange} valueChanged={(dr) => {
                        this.queryValues.dateRange = dr;
                        this.loadData();
                    }} />
                </Grid>
                <Grid item>
                    <FilterSelector
                        typeName="devices"
                        typeNameSingular="device"
                        allValues={this.mapFpgadnaToFriendlyName(this.availableValues['device'])}
                        availableValues={this.mapFpgadnaToFriendlyName(this.limitedAvailableValues['device'])}
                        selectedValues={this.mapFpgadnaToFriendlyName(this.queryValues.devices)}
                        valueChanged={(vals) => { this.queryValues.devices = this.mapFriendlyNameToFpgadna(vals); this.loadData(); }}
                    />
                </Grid>
                <Grid item>
                    <FilterSelector
                        typeName="jobs"
                        typeNameSingular="job"
                        allValues={this.availableValues['job'] || []}
                        availableValues={this.limitedAvailableValues['job'] || []}
                        selectedValues={this.queryValues.jobs}
                        valueChanged={(vals) => { this.queryValues.jobs = vals; this.loadData(); }}
                    />
                </Grid>
                <Grid item>
                    <FilterSelector
                        typeName="algorithms"
                        typeNameSingular="algorithm"
                        allValues={this.availableValues['algorithm'] || []}
                        availableValues={this.limitedAvailableValues['algorithm'] || []}
                        selectedValues={this.queryValues.algorithms}
                        valueChanged={(vals) => { this.queryValues.algorithms = vals; this.loadData(); }}
                    />
                </Grid>
                <Grid item>
                    <FilterSelector
                        typeName="chemicals"
                        typeNameSingular="chemical"
                        allValues={this.availableValues['chemical'] || []}
                        availableValues={this.limitedAvailableValues['chemical'] || []}
                        selectedValues={this.queryValues.chemicals}
                        valueChanged={(vals) => { this.queryValues.chemicals = vals; this.loadData(); }}
                    />
                </Grid>
                {this.renderTagFilters()}
                <Grid item>
                    <FilterSelector
                        typeName="durations"
                        typeNameSingular="duration"
                        allValues={this.availableValues['duration'] || []}
                        availableValues={this.limitedAvailableValues['duration'] || []}
                        selectedValues={this.queryValues.durations.map(v => v.toString())}
                        valueChanged={(vals) => { this.queryValues.durations = vals.map(v => parseInt(v)); this.loadData(); }}
                        numericValues={true}
                    />
                </Grid>
                <Grid item>
                    <GroupSplitSelector
                        splitByKeys={this.queryValues.splitByKeys}
                        dataSeriesKeys={this.queryValues.dataSeriesKeys}
                        selectableValues={this.selectableValues}
                        valueChanged={(splitByKeys, dataSeriesKeys) => {
                            this.queryValues.splitByKeys = splitByKeys;
                            this.queryValues.dataSeriesKeys = dataSeriesKeys;
                            this.loadData();
                        }}
                    />
                </Grid>
                <Grid item>
                    <Link className={classes.button} component="button" variant="inherit" color="inherit" onClick={() => this.resetFilters()}>Reset</Link>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Switch size={"small"} checked={this.showLegend} onChange={(e) => this.showLegend = e.currentTarget.checked} />}
                        label="Show legend"
                    />
                </Grid>
                { this.props.appState.preferences?.flowMode &&
                <Grid item>
                    <FormControlLabel
                        control={<Switch size={"small"} checked={this.queryValues.includeDiagnostics} onChange={(e) => {
                            this.queryValues.includeDiagnostics = e.currentTarget.checked;
                            this.loadData();
                        }} />}
                        label="Include diagnostics"
                    />
                </Grid>
                }
            </React.Fragment>
        );
    }

    private resetFilters()
    {
        if (this.props.appState.username?.toLowerCase() === "natureenergytruck1@nanonord.dk") {
            this.startSubtract = 168;
            this.queryValues.dateRange.start = moment().subtract(this.startSubtract, 'hour').toDate();
        }
        this.queryValues.dateRange = {
            start: moment().subtract(this.startSubtract, 'hour').toDate(),
            end: moment().toDate()
        };
        this.queryValues.devices = [];
        this.queryValues.jobs = [];
        this.queryValues.algorithms = [];
        this.queryValues.chemicals = [];
        this.queryValues.sampleTags = [];
        this.queryValues.sampleTagsParts = [];
        this.queryValues.durations = [];

        this.queryValues.splitByKeys = ['device'];
        this.queryValues.dataSeriesKeys = ['chemical'];
        
        this.loadData();
    }

    private renderTagFilters() {
        if(!this.useTagParts) {
            return (                
                <Grid item>
                    <FilterSelector
                        typeName="tags"
                        typeNameSingular="tag"
                        allValues={this.availableValues['sampletag'] || []}
                        availableValues={this.limitedAvailableValues['sampletag'] || []}
                        selectedValues={this.queryValues.sampleTags}
                        valueChanged={(vals) => { this.queryValues.sampleTags = vals; this.loadData(); }}
                    />
                </Grid>
            );
        } else {
            return (
                <React.Fragment>
                    {this.tagParts.map((tag, idx) => 
                        <Grid item key={idx}>
                            <FilterSelector
                                typeName={tag+'s'}
                                typeNameSingular={tag}
                                allValues={this.availableValues['sampletag' + idx] || []}
                                availableValues={this.limitedAvailableValues['sampletag' + idx] || []}
                                selectedValues={this.queryValues.sampleTagsParts[idx]}
                                valueChanged={(vals) => { this.queryValues.sampleTagsParts[idx] = vals; this.loadData(); }}
                            />
                        </Grid>
                )}
                </React.Fragment>
            );
        }
    }

    private mapFpgadnaToFriendlyName(ids: string[]): string[] {
        if (!ids) {
            return [];
        }
        return ids.map(id => {
            var dev = this.props.appState.authenticatedDevices!.find(dev => dev.fpgaDna === id);
            return dev && dev.friendlyName ? dev.friendlyName : id;
        });
    }
    private mapFriendlyNameToFpgadna(ids: string[]): string[] {
        if (!ids) {
            return [];
        }
        return ids.map(id => {
            var dev = this.props.appState.authenticatedDevices!.find(dev => dev.friendlyName === id);
            return dev && dev.fpgaDna ? dev.fpgaDna : id;
        });
    }

    // ----------------------------- Graphs -----------------------------

    private renderGraphs() {
        if (this.loadingData) {
            return (
                <div>
                    <CircularProgress style={{ marginLeft: '50%', marginTop: 20 }} />
                </div>
            );
        }
        if (this.graphs === undefined) {
            return (
                <div/>
            );
        }
        let valueExtractorFactory = new ValueExtractorFactoryImpl(this.props.appState);
        let legendKey = this.queryValues.dataSeriesKeys.map(k => this.selectableValues.get(k)).join(', ');
        return (<ChartsComponent
            appState={this.props.appState}
            dateRange={this.queryValues.dateRange}
            dataUrl={this.dataUrl}
            graphs={this.graphs}
            legendKey={legendKey}
            showLegend={this.showLegend}
            dateRangeSelected={(dr) => { 
                this.queryValues.dateRange.start = dr.start; 
                this.queryValues.dateRange.end = dr.end; 
                this.loadData() 
            }}
            valueExtractorFactory={valueExtractorFactory}
        />);
    }

    private processGraphs(graphs: GraphInfo[]) {
        let fpgadnaToFriendlyNameMap = this.props.appState.authenticatedDevices!.reduce((map, dev) => { map.set(dev.fpgaDna, dev.friendlyName || 'Unknown'); return map;}, new Map<string, string>());

        // Check and translate device names in splits and groups
        let splitIdx = this.queryValues.splitByKeys.indexOf('device');
        if(splitIdx >= 0) {
            graphs.forEach(g => {
                if(g.name.startsWith('Diag|')) {
                    return;
                }
                let nameParts = g.name.split('|');
                nameParts[splitIdx] = fpgadnaToFriendlyNameMap.get(nameParts[splitIdx]) || 'Unknown';
                g.name = nameParts.join('|');
            });
        }
        let dsIdx = this.queryValues.dataSeriesKeys.indexOf('device');
        if(dsIdx >= 0) {
            graphs.forEach(g => {
                if(g.name.startsWith('Diag|')) {
                    return;
                }
                g.dataSeries.forEach(ds => {
                    let nameParts = ds.name.split('|');
                    nameParts[dsIdx] = fpgadnaToFriendlyNameMap.get(nameParts[dsIdx]) || 'Unknown';
                    ds.name = nameParts.join('|');
                });
                sortList(g.dataSeries, 'name');
            });
        }
        graphs.forEach(g => {
            if(!g.name.startsWith('Diag|')) {
                return;
            }
            let nameParts = g.name.split('|');
            nameParts[1] = fpgadnaToFriendlyNameMap.get(nameParts[1]) || 'Unknown';
            g.name = nameParts.join('|');
        });
        sortList(graphs, 'name');
        graphs.forEach(g => sortList(g.dataSeries, 'name'));
    }

    private initializeData() {
        this.loadingData = true;
        this.props.appState.serverFacade.statistics().lastGraphRequest(
            (lastRequest) => {
                this.queryValues.devices = lastRequest.devices;
                this.queryValues.jobs = lastRequest.jobs;
                this.queryValues.algorithms = lastRequest.algorithms;
                this.queryValues.chemicals = lastRequest.chemicals;
                this.queryValues.sampleTags = lastRequest.sampleTags;
                this.queryValues.sampleTagsParts = lastRequest.sampleTagParts;
                this.queryValues.durations = lastRequest.durations;

                this.queryValues.includeDiagnostics = lastRequest.includeDiagnostics;
    
                this.queryValues.splitByKeys = lastRequest.splitKeys;
                this.queryValues.dataSeriesKeys = lastRequest.dataSeriesKeys;

                this.loadData();
            }
            , (error) => {
                this.loadData();
            });
    }

    private loadData() {
        this.loadingData = true;
        let request: GraphsRequest = {                
            from: moment(this.queryValues.dateRange.start).utc().toDate(),
            to: moment(this.queryValues.dateRange.end).utc().toDate(),

            devices: this.queryValues.devices,
            jobs: this.queryValues.jobs,
            algorithms: this.queryValues.algorithms,
            chemicals: this.queryValues.chemicals,
            sampleTags: this.queryValues.sampleTags,
            sampleTagParts: this.queryValues.sampleTagsParts,
            durations: this.queryValues.durations,

            includeDiagnostics: this.queryValues.includeDiagnostics,

            splitKeys: this.queryValues.splitByKeys,
            dataSeriesKeys: this.queryValues.dataSeriesKeys
        };
        this.props.appState.serverFacade.statistics().graphs(
            request,
            (result, url) => {
                this.processGraphs(result.graphs);
                result.availableValues['device'] = this.props.appState.authenticatedDevices?.map(dev => dev.fpgaDna);
                runInAction(() => {
                    this.dataUrl = url;
                    this.graphs = result.graphs;
                    this.limitedAvailableValues = result.limitedAvailableValues;
                    this.availableValues = result.availableValues;
                    this.loadingData = false;
                });
                if(result.note) {
                    this.props.appState.snackbarMessage = result.note;
                 }
         },
            (reason) => {
                this.loadingData = false;
                this.props.appState.snackbarMessage = 'Unable to load statistics: ' + reason;
            }
        );
    }
}

class ValueExtractorFactoryImpl implements DataRowValueExtractorFactory {
    private readonly appState: ApplicationState;
    constructor(appState: ApplicationState) {
        this.appState = appState;
    }

    newInstance(graphInfo: GraphInfo): DataRowValueExtractor {
        return new ValueExtractorImpl(graphInfo, this.appState);
    }
}
class ValueExtractorImpl implements DataRowValueExtractor {
    private readonly graphInfo: GraphInfo;
    private readonly preferences: Preferences;
    private readonly tagParts: string[];
    private readonly useTagParts: boolean;

    private readonly fpgadnaToFriendlyNameMap: Map<string, string>;
    private readonly columnIndex: Map<string, number> = new Map<string, number>();

    constructor(graphInfo: GraphInfo, appState: ApplicationState) {
        this.graphInfo = graphInfo;
        this.preferences = appState.preferences!;
        this.tagParts = appState.preferences!.tagParts;
        this.useTagParts = this.tagParts.length > 0;
        this.fpgadnaToFriendlyNameMap = appState.authenticatedDevices!.reduce((map, dev) => { map.set(dev.fpgaDna, dev.friendlyName || 'Unknown'); return map;}, new Map<string, string>());
    }
    private isMeasurement(): boolean {
        return !this.graphInfo.name.startsWith('Diag|');
    }

    showValue(row: any): boolean {
        return this.isMeasurement();
    }

    dataPointColor(graph: GraphInfo, ds: DataSerie, dp: DataPoint): string | undefined {
        let ret: string | undefined = undefined;
        graph.alarms.forEach(a => {
            if(dp.augmentValues[ds.key + '_' + a.key + '_triggered']) {
                ret = a.signal;
            }
        });
        return ret;
    }

    timestamp(row: any): number {
        return row[this.getColumn('timestamp')];
    }
    headline(row: any): string {
        return row[this.getColumn(this.useTagParts ? 'sampletag0' : 'sampletag')];
    }
    subheader(row: any): string {
        return this.fpgadnaToFriendlyNameMap.get(row[this.getColumn('device')]) || 'Unknown';
    }
    additionalValues(dataSeries: string, dp: DataPoint): Map<string, string> {
        var ret = new Map<string, string>();
        var row = dp.rowValues[dataSeries];
        if(this.isMeasurement()) {
            if(!row) {
                return ret;
            }
            if(this.useTagParts) {
                for(var i=0; i<this.tagParts.length; i++) {
                    ret.set(this.tagParts[i], row[this.getColumn('sampletag' + i)]);
                }
            }
            ret.set('Job', row[this.getColumn('job')]);
            ret.set('Algorithm', row[this.getColumn('algorithm')]);
            ret.set('Duration', row[this.getColumn('duration')]);
            const chemical = row[this.getColumn('chemical')];
            ret.set('Chemical', chemical);

            this.graphInfo.alarms.forEach(alarm => {
                ret.set(alarm.name + ' enabled', '' + dp.augmentValues[dataSeries + '_' + alarm.key + '_enabled']);
                ret.set(alarm.name + ' triggered', '' + dp.augmentValues[dataSeries + '_' + alarm.key + '_triggered']);
            });
        } else {
            ret.set('Flow value', row[this.getColumn('Value')]);
            ret.set('Flow threshold', row[this.getColumn('Threshold')]);
            ret.set('Flow detected', row[this.getColumn('Detected')]);
        }
        return ret;
    }
    value(row: any): string {
        return row[this.getColumn('value')] + (this.preferences.usePercentages ? ' %' : ' ppm');
    }
    private getColumn(column: string): number {
        let val = this.columnIndex.get(column);
        if(val !== undefined) {
            return val;
        }
        val = this.graphInfo.dataRowColumns.indexOf(column);
        this.columnIndex.set(column, val);
        return val;
    }

}

export const Statistics = withStyles(styles)(StatisticsImpl);


