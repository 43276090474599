import ApplicationState from "../../ApplicationState";
import { observer } from "mobx-react";
import { Component } from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { styles } from "../../Styles";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { SampleList } from "./SampleList";
import { NewSample } from "./NewSample";
import { ViewSample } from "./ViewSample";

interface MatchParams {
    company: string;
    id?: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
}

export interface SampleManagerProps {
    appState: ApplicationState;
}

@observer
class SampleManagerImpl extends Component<SampleManagerProps & WithStyles<typeof styles>, any> {
    public render() {
        return (
            <Switch>
                <Route path={'/sample/list'} exact>
                    <SampleList appState={this.props.appState} />
                </Route>
                <Route path={'/sample/new/:company'} exact render={( {match}: MatchProps) => (
                    <NewSample appState={this.props.appState} company={Number.parseInt(match.params.company)} /> )} />
                <Route path={'/sample/view/:company/:id'} exact render={( {match}: MatchProps) => (
                    <ViewSample appState={this.props.appState} company={Number.parseInt(match.params.company)} sampleId={Number.parseInt(match.params.id!)}/> )} />
          </Switch>
        );
    }
}

export const SampleManager = withStyles(styles)(SampleManagerImpl);
