import ApplicationState from "../ApplicationState";
import ServerFacadeAuthImpl, { ServerFacadeAuth } from "./ServerFacadeAuthentication";
import ServerFacadePreferencesImpl, { ServerFacadePreferences } from "./ServerFacadePreferences";
import ServerFacadeDevsImpl, { ServerFacadeDevs } from "./ServerFacadeDevs";
import ServerFacadeStatsImpl, { ServerFacadeStats } from "./ServerFacadeStats";
import CategoryDefinitionsManagerImpl, {CategoryDefinitionsManager } from "./ServerFacadeCategoryDefinitionsManager";
import ServerFacadeSampleManagerImpl, { ServerFacadeSampleManager } from "./ServerFacadeSampleManager";

export interface ServerFacade {
    endpointUrl(): string;
    authentication(): ServerFacadeAuth;
    preferences(): ServerFacadePreferences;
    devices(): ServerFacadeDevs;
    statistics(): ServerFacadeStats;
    categoryDefinitionsManager(): CategoryDefinitionsManager;
    sampleManager(): ServerFacadeSampleManager;
 }

export default class ServerFacadeImpl implements ServerFacade, WebRequestExecutor {
    //private static endpointUrl = "https://localhost:44308";
    //private static endpointUrl = "https://192.168.210.123:5443";
    private static endpointUrl = "https://app.tveskaeg.com";
    private appState: ApplicationState;

    public constructor(appState: ApplicationState) {
        this.appState = appState;
    }

    // ------------------------------------------------------- ServerFacade -------------------------------------------------------

    public authentication(): ServerFacadeAuth { return new ServerFacadeAuthImpl(this); }
    public preferences(): ServerFacadePreferences { return new ServerFacadePreferencesImpl(this); }
    public devices(): ServerFacadeDevs { return new ServerFacadeDevsImpl(this); }
    public statistics(): ServerFacadeStats { return new ServerFacadeStatsImpl(this, this.appState); }
    public categoryDefinitionsManager(): CategoryDefinitionsManager { return new CategoryDefinitionsManagerImpl(this); }
    public sampleManager(): ServerFacadeSampleManager { return new ServerFacadeSampleManagerImpl(this, this.appState); }

    public endpointUrl(): string {
        return ServerFacadeImpl.endpointUrl;
    }

    public execute(url: string, queryParams: any, success: (json: any) => void, fail: (reason: string, status?: number) => void, body?: any, requestType: string = 'GET', token: string | undefined = this.appState.authenticationToken) {
        url = ServerFacadeImpl.endpointUrl + url;
        const headers:HeadersInit = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        if (token) {
            headers.Authorization = 'bearer ' + token;
        }
        const init: RequestInit = {
            mode: "cors",
            headers: headers,
            method: requestType,
        };
        if (body) {
            init.body = JSON.stringify(body);
        }
        if (queryParams) {
            let idx = 0;
            Object.keys(queryParams).forEach((key) => {
                url += (idx++ === 0 ? '?' : '&') + key + '=' + queryParams['key'];
            });
        }
        fetch(url, init)
            .then((response) => {
                if (response.status === 200) {
                    response.json()
                        .then((json) => { success(json) })
                        .catch((reason) => {
                            fail(String(reason))
                        })
                } else {
                    var err = String(response.status) + ': ' + response.statusText;
                    response.json()
                        .then((json) => { 
                            if(json.detail) {
                                err = err + ' - ' + json.detail;
                            }
                            if(typeof json === "string")
                                err = `${err}  ${json}`;
                            fail(err, response.status);
                        })
                        .catch((reason) => {
                            fail(err, response.status);
                        })
                }
            })
            .catch((reason) => {
                fail(String(reason));
            });
    }
}

export interface WebRequestExecutor {
    execute(url: string, queryParams: any, success: (json: any) => void, fail: (reason: string, status?: number) => void, body?: any, requestType?: string, token?: string): void;
    endpointUrl(): string;
}
