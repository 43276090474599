import React, { Component } from "react";
import { observer } from "mobx-react";
import { styles } from "../../Styles";
import { action, observable } from "mobx";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, withStyles, WithStyles } from "@material-ui/core";
import { CategoryValue } from "../../Services/ServerFacadeCategoryDefinitionsManager";

export interface CategoryValueEditorProps {
    categoryValue: CategoryValue;
    takenIds: string[];
    cancelCallback: () => void;
    saveCallback: () => void;
}

@observer
class CategoryValueEditorImpl extends Component<CategoryValueEditorProps & WithStyles<typeof styles>, any> {

    private newValue: boolean = false;

    @observable
    private id: string = '';

    @observable
    private label: string = '';

    public componentWillMount() {
        this.newValue = this.props.categoryValue.id === '';
        this.id = this.props.categoryValue.id;
        this.label = this.props.categoryValue.label;
    }

    public render() {
        const title = this.newValue ? 'New category value' : 'Editing ' + this.id;

        return (
            <React.Fragment>
                <Dialog open={true} onClose={() => this.props.cancelCallback()}>
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please fill out the category value fields
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="id"
                            label="ID"
                            disabled={!this.newValue}
                            error={!this.isIdOk()}
                            value={this.id}
                            onChange={(e) => this.id = e.target.value}
                        />
                        <TextField
                            margin="dense"
                            id="label"
                            label="Label"
                            fullWidth
                            error={!this.isLabelOk()}
                            value={this.label}
                            onChange={(e) => this.label = e.target.value}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.cancelCallback()} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={!this.isFormOk()} onClick={() => this.saveValues()} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    private isIdOk() { 
        return this.id && this.id.trim() !== '' && (!this.newValue || !this.props.takenIds.includes(this.id)); 
    }
    private isLabelOk() { return this.label && this.label.trim() !== ''; }
    private isFormOk() { return this.isIdOk() && this.isLabelOk(); }

    @action
    private saveValues() {
        this.props.categoryValue.id = this.id;
        this.props.categoryValue.label = this.label;
        this.props.saveCallback();
    }
}


export const CategoryValueEditor = withStyles(styles)(CategoryValueEditorImpl);