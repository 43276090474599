import React from "react";
import { observer } from "mobx-react";
import { Button, FormControl, Grid, Input, InputLabel, Typography, WithStyles, withStyles } from "@material-ui/core";
import { styles } from "../../Styles";
import ApplicationState from "../../ApplicationState";
import { AbstractLoadingComponent } from "../AbstractLoadingComponent";
import { Sample } from "../../Services/ServerFacadeSampleManager";
import { observable } from "mobx";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

export interface NewSampleProps extends RouteComponentProps<any>, WithStyles<typeof styles> {
    appState: ApplicationState;
    company: number;
}

@observer
class NewSampleImpl extends AbstractLoadingComponent<NewSampleProps, any> {

    @observable
    private sample: Sample = {
        id: -1,
        sampleId: '',
        operatorId: '',
        createdDate: new Date(),
        numberOfMeasurements: 0,
        testTubes: [
            {
                testTubeId: '',
                preperationDate: new Date()
            },
            {
                testTubeId: '',
                preperationDate: new Date()
            },
            {
                testTubeId: '',
                preperationDate: new Date()
            },
        ]
    };

    public doRender() {
        const classes = this.props.classes;
        return (
            <React.Fragment>
                <form>
                    <Grid container spacing={3} direction={'column'}>
                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>New sample!</Typography>
                        </Grid>
                        <Grid item>
                            <FormControl margin="normal" required={true} error={this.isIdInvalid()}>
                                <InputLabel htmlFor="sampleId">Sample ID</InputLabel>
                                <Input id="sampleId" name="sampleId" autoFocus={true} value={this.sample.sampleId} onChange={(e) => this.sample.sampleId = e.target.value} onKeyDown={(e) => this.handleEnter(e)}/>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl margin="normal">
                                <InputLabel htmlFor="operatorId">Operator ID</InputLabel>
                                <Input id="operatorId" name="operatorId" value={this.sample.operatorId} onChange={(e) => this.sample.operatorId = e.target.value} onKeyDown={(e) => this.handleEnter(e)}/>
                            </FormControl>
                        </Grid>
                        {this.sample.testTubes.map((tt, idx) => 
                            <Grid item key={idx}>
                                <FormControl margin="normal" error={this.isTestTubeInvalid(idx)}>
                                    <InputLabel htmlFor={'tt' + idx + 'Id'}>Tube tag {idx+1}</InputLabel>
                                    <Input id={'tt' + idx + 'Id'} name={'tt' + idx + 'Id'} value={this.sample.testTubes[idx].testTubeId} onChange={(e) => this.sample.testTubes[idx].testTubeId = e.target.value} onKeyDown={(e) => this.handleEnter(e)}/>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item>
                            <Button variant={'contained'} color={'primary'} className={classes.buttonWithSpacing} disabled={this.isInvalid()} onClick={() => this.handleSave()}>Save</Button>
                            <Button variant={'contained'} color={'default'} className={classes.buttonWithSpacing} component={({innerRef,...props}) => <Link {...props} to={'/sample/list'}/>}>Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </React.Fragment>
        );
    }

    private handleEnter(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if(event.keyCode === 13) {
            event.preventDefault();
            const form = event.currentTarget.form;
            const index = Array.prototype.indexOf.call(form, event.currentTarget);
            if(form!.elements.length <= index+1) {
                return;
            }
            const nextElement: any = form!.elements[index + 1];
            nextElement.focus();
        }
    }

    private handleSave() {
        const sm = this.props.appState.serverFacade.sampleManager();
        this.loading = true;
        sm.saveSample(
            this.props.company,
            this.sample,
            () => {
                this.props.history.push('/sample/list');
                this.props.appState.snackbarMessage = 'New sample created!';
            },
            (reason) => {
                this.props.appState.snackbarMessage = reason;
                this.loading = false;
            }
        );

    }

    private isInvalid(): boolean {
        return this.isIdInvalid() || this.isTestTubesInvalid();
    }

    private isIdInvalid(): boolean {
        const id = this.sample.sampleId;
        return id === undefined || id === null || id.trim() === '';
    }

    private isTestTubesInvalid(): boolean {
        for(let i=0; i<this.sample.testTubes.length; i++) {
            if(this.isTestTubeInvalid(i)) {
                return true;
            }
        }
        return false;
    }

    private isTestTubeInvalid(idx: number): boolean {
        const tt = this.sample.testTubes[idx];
        return tt.testTubeId === undefined || tt.testTubeId === null || tt.testTubeId.trim() === '';
    }
}

export const NewSample = withRouter(withStyles(styles)(NewSampleImpl));