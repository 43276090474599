import ApplicationState from "../ApplicationState";
import { WebRequestExecutor } from "./ServerFacade";

export interface ServerFacadeSampleManager {
    listSamples(company: number, query: SampleQuery, success: (result: SampleStatistics[]) => void, fail: (reason: string, status?: number) => void): void;
    loadSample(company: number, id: number, success: (result: Sample) => void, fail: (reason: string, status?: number) => void): void;
    saveSample(company: number, sample: Sample, success: () => void, fail: (reason: string, status?: number) => void): void;
    markAsProcessed(company: number, ids: number[], success: () => void, fail: (reason: string, status?: number) => void): void;
    deleteSample(company: number, id: number, success: () => void, fail: (reason: string, status?: number) => void): void;
    prepareExcelDownload(company: number, query: SampleQuery, success: (downloadUrl: string) => void, fail: (reason: string, status?: number) => void): void;
}

export default class ServerFacadeSampleManagerImpl implements ServerFacadeSampleManager {
    private readonly webRequestExecutor: WebRequestExecutor;
    private readonly appState: ApplicationState;

    public constructor(webRequestExecutor: WebRequestExecutor, appState: ApplicationState) {
        this.webRequestExecutor = webRequestExecutor;
        this.appState = appState;
    }

    listSamples(company: number, query: SampleQuery, success: (result: SampleStatistics[]) => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute(`/api/samples/${company}/operations/query`, null, success, fail, query, 'POST');
    }

    loadSample(company: number, id: number, success: (result: Sample) => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute(`/api/samples/${company}/${id}`,null, success,fail);
    }

    saveSample(company: number, sample: Sample, success: () => void, fail: (reason: string, status?: number) => void): void {
        if(sample.id === -1)
            this.webRequestExecutor.execute(`/api/samples/${company}`, null, success, fail, sample, 'POST');
        else
            this.webRequestExecutor.execute(`/api/samples/${company}/${sample.id}`, null, success, fail, sample, 'PUT');
    }

    markAsProcessed(company: number, ids: number[], success: () => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute(`/api/samples/${company}/operations/markAsProcessed`, null, success, fail, ids, 'PUT');
    }
    
    deleteSample(company: number, id: number, success: () => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute(`/api/samples/${company}/${id}`, null, success, fail, null, 'DELETE');
    }

    prepareExcelDownload(company: number, query: SampleQuery, success: (downloadUrl: string) => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute(`/api/samples/${company}/operations/export`, null
        , (json) => success(this.webRequestExecutor.endpointUrl() + `/api/excel/samples/${json.exportId}?Authorization=bearer ${this.appState.authenticationToken}`)
        , fail, query, 'POST');
    }
}

// ----------------------------------------- Model -----------------------------------------

export interface SampleQuery {
    includeProcessed?: boolean;
    includeUnmeasuredSamples?: boolean;
    page: number;
    pageSize: number;
    fromDate?: Date;
    toDate?: Date;
}

export interface SampleStatistics {
    id: number;
    sampleId: string;
    createdDate: Date;
    numberOfMeasurements: number;
    validity: string;
    chemicals: ChemicalStatistics[]
}

export interface ChemicalStatistics {
    name: string;
    avg: number;
    std: number;
}

export interface Sample {
    id: number;
    sampleId: string;
    operatorId?: string;
    createdDate: Date;
    processedDate?: Date;
    testTubes: TestTube[];

    numberOfMeasurements: number; // dynamically calculated
    measurements?: MeasurementRun[]
}

export interface TestTube {
    testTubeId: string;
    preperationDate?: Date;
}

export interface MeasurementRun {
    time: Date;
    runId: string;
    
    testTubeId: string;

    deviceId: string;
    deviceName: string;

    results: MeasurementResult[];
}

export interface MeasurementResult {
    chemicalId: string;
    chemicalName: string;

    result: number;
    resultUnits: string;
}


// ----------------------------------------- Mock data -----------------------------------------

// const mockSamples: Sample[] = [
//     {
//         id: 'CU001',
//         operatorId: 'Mathilda',
//         createdDate: new Date('2020-12-05T11:48:12'),

//         numberOfMeasurements: 3,
//         measurements: [
//             {
//                 time: new Date('2020-12-06T11:48:12'),
//                 runId: 'r1',
//                 testTubeId: 'C0001',
//                 deviceId: 'd1',
//                 deviceName: 'Device 1',
//                 results: [
//                     {
//                         chemicalId: 'nh4',
//                         chemicalName: 'N.NH4',
//                         result: 2612,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'ntot',
//                         chemicalName: 'N.TOT',
//                         result: 2923,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'po4',
//                         chemicalName: 'P.PO4',
//                         result: 135,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'ptot',
//                         chemicalName: 'P.TOT',
//                         result: 132,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'tos',
//                         chemicalName: 'Total Solids',
//                         result: 14568,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'ph',
//                         chemicalName: 'Ph',
//                         result: 7.6,
//                         resultUnits: 'ph',
//                     },
//                 ]
//             },
//             {
//                 time: new Date('2020-12-06T11:41:45'),
//                 runId: 'r2',
//                 testTubeId: 'C0002',
//                 deviceId: 'd2',
//                 deviceName: 'Device 2',
//                 results: [
//                     {
//                         chemicalId: 'nh4',
//                         chemicalName: 'N.NH4',
//                         result: 2695,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'ntot',
//                         chemicalName: 'N.TOT',
//                         result: 2912,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'po4',
//                         chemicalName: 'P.PO4',
//                         result: 138,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'ptot',
//                         chemicalName: 'P.TOT',
//                         result: 133,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'tos',
//                         chemicalName: 'Total Solids',
//                         result: 14412,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'ph',
//                         chemicalName: 'Ph',
//                         result: 7.9,
//                         resultUnits: 'ph',
//                     },
//                 ]
//             },
//             {
//                 time: new Date('2020-12-06T11:45:27'),
//                 runId: 'r3',
//                 testTubeId: 'C0003',
//                 deviceId: 'd3',
//                 deviceName: 'Device 3',
//                 results: [
//                     {
//                         chemicalId: 'nh4',
//                         chemicalName: 'N.NH4',
//                         result: 2608,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'ntot',
//                         chemicalName: 'N.TOT',
//                         result: 2942,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'po4',
//                         chemicalName: 'P.PO4',
//                         result: 134,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'ptot',
//                         chemicalName: 'P.TOT',
//                         result: 138,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'tos',
//                         chemicalName: 'Total Solids',
//                         result: 14405,
//                         resultUnits: 'ppm',
//                     },
//                     {
//                         chemicalId: 'ph',
//                         chemicalName: 'Ph',
//                         result: 7.8,
//                         resultUnits: 'ph',
//                     },
//                 ]
//             },
//         ],
//         testTubes: [
//             {
//                 testTubeId: 'C0001',
//                 preperationDate: new Date('2020-12-05T11:48:12')
//             },
//             {
//                 testTubeId: 'C0002',
//                 preperationDate: new Date('2020-12-05T11:48:12')
//             },
//             {
//                 testTubeId: 'C0003',
//                 preperationDate: new Date('2020-12-05T11:48:12')
//             },
//         ]
//     },
//     {
//         id: 'CU002',
//         operatorId: 'Kurt',
//         createdDate: new Date('2020-12-06T12:43:23'),

//         numberOfMeasurements: 2,
//         testTubes: [
//             {
//                 testTubeId: 'C0004',
//                 preperationDate: new Date('2020-12-05T11:48:12')
//             },
//             {
//                 testTubeId: 'C0005',
//                 preperationDate: new Date('2020-12-05T11:48:12')
//             },
//             {
//                 testTubeId: 'C0006',
//                 preperationDate: new Date('2020-12-05T11:48:12')
//             },
//         ]
//     },
// ];
