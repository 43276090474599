import ApplicationState from "../ApplicationState";
import { Devices } from "./Devices";
import { AbstractLoadingComponent } from "./AbstractLoadingComponent";
import { WithStyles, withStyles, AppBar, Toolbar, IconButton, Typography, Drawer, Box, Divider, List, Link as MULink, ListItem, ListItemIcon, ListItemText, Button} from "@material-ui/core";
import { styles } from "../Styles";
import { observer } from "mobx-react";
import React from "react";
import clsx from 'clsx';
import { BrowserRouter, Route, Link } from 'react-router-dom';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';

import { observable } from "mobx";
import { Devices as DevicesIcon, AccountTreeRounded, SettingsInputAntennaRounded } from "@material-ui/icons";
import { DashboardHome } from "./DashboardHome";
import { PreferencesComponent } from "./Preferences";
import { Statistics } from "./Statistics/Statistics";
import { VncView } from "./VncView";
import { CategoryDefinitionManager } from "./Categories/CategoryDefinitionManager";
import { SampleManager } from "./Samples/SampleManager";

export interface DashboardProps {
    appState: ApplicationState;
}

@observer
class DashboardImpl extends AbstractLoadingComponent<DashboardProps & WithStyles<typeof styles>, any> {

    @observable
    private leftMenuOpen: boolean = false;

    private selectedDevice?: string;

    protected doRender() {
        const classes = this.props.classes;

        return (
            <BrowserRouter>
                <div className={classes.root}>
                    {this.renderAppBar()}
                    {this.renderLeftMenu()}
                    {this.renderRightContent()}
                </div>
            </BrowserRouter>
        );
    }

    private renderAppBar() {
        const classes = this.props.classes;
        return (
            <AppBar position="absolute" className={clsx(classes.appBar, this.leftMenuOpen && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => this.leftMenuOpen = true}
                    className={clsx(classes.menuButton, this.leftMenuOpen && classes.menuButtonHidden)}
                >
                    <MenuIcon />
                </IconButton>
                <img alt={'logo'} className={classes.appBarTveskaegLogo} src={'/img/tveskaeg_logo.png'} />
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    Tveskaeg Management
                </Typography>
                <img alt={'logo'} className={classes.appBarNanonordLogo} src={'/img/nanonord_logo.png'} />
                <Button href="#" variant="outlined" className={classes.logoutButton} onClick={() => this.handleLogout()}>
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
        );
    }

    private renderLeftMenu() {
        const classes = this.props.classes;
        return (
            <Drawer
            variant="permanent"
            classes={{ paper: clsx(classes.drawerPaper, !this.leftMenuOpen && classes.drawerPaperClose), }}
            open={this.leftMenuOpen}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={() => this.leftMenuOpen = false}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List>
                <Link to={'/'} style={{ textDecoration: 'none' }}>
                    <ListItem button={true}>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                    </ListItem>
                </Link>
                <Link to={'/devices'} style={{ textDecoration: 'none' }}>
                    <ListItem button={true}>
                            <ListItemIcon>
                            <DevicesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Devices" />
                    </ListItem>
                </Link>
                <Link to={'/statistics'} style={{ textDecoration: 'none' }}>
                    <ListItem button={true}>
                            <ListItemIcon>
                                <BarChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Statistics" />
                    </ListItem>
                </Link>
                { (this.props.appState.username === 'bo.gundersen@gmail.com') &&
                    <Link to={'/sample/list'} style={{ textDecoration: 'none' }}>
                        <ListItem button={true}>
                                <ListItemIcon>
                                    <SettingsInputAntennaRounded />
                                </ListItemIcon>
                                <ListItemText primary="Samples" />
                        </ListItem>
                    </Link>
                }
                { (this.props.appState.username === "fs@nanonord.dk") &&
                <React.Fragment>
                <Divider />
                    <Link to={'/catdef/list'} style={{ textDecoration: 'none' }}>
                        <ListItem button={true}>
                                <ListItemIcon>
                                    <AccountTreeRounded />
                                </ListItemIcon>
                                <ListItemText primary="Category Definitions" />
                        </ListItem>
                    </Link>
                </React.Fragment>
                }
                { (!(this.props.appState.username?.toLowerCase() == "natureenergytruck1@nanonord.dk")) &&

                <React.Fragment>
                <Divider />
                <Link to={'/preferences'} style={{ textDecoration: 'none' }}>
                    <ListItem button={true}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                    </ListItem>
                </Link>
                </React.Fragment>
                }
            </List>
        </Drawer>
        );
    }

    private renderRightContent() {
        const classes = this.props.classes;
        return (
            <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div className={classes.dashboardcontainer}>
                <Route path="/" exact={true} render={(ctx) =>
                    <DashboardHome 
                    appState={this.props.appState} 
                    machineSelected={(deviceId) => {
                        this.selectedDevice = deviceId;
                        ctx.history.push('/statistics');
                    }}
                />
            }>
                </Route>
                <Route path="/devices/:id" render={( match => ( <VncView appState={this.props.appState} deviceId={match.match.params.id}/> )) } />
                <Route path="/devices" exact={true}>
                    <Devices appState={this.props.appState} />
                </Route>
                <Route path="/statistics">
                <Statistics 
                    appState={this.props.appState} 
                    queryValuesInitializer={(qv) => {
                        if(this.selectedDevice) {
                            qv.devices.push(this.selectedDevice);
                            this.selectedDevice = undefined;
                        }
                    }}
                />
                </Route>
                <Route path="/sample">
                    <SampleManager appState={this.props.appState} />
                </Route>
                <Route path="/catdef">
                    <CategoryDefinitionManager appState={this.props.appState} />
                </Route>
                <Route path="/preferences">
                    <PreferencesComponent appState={this.props.appState} />
                </Route>
            </div>
            <Box>
                {this.copyright()}
            </Box>
        </main>
        );
    }

    private handleLogout() {
        this.props.appState.logout();
    }

    private copyright() {
        return (<Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <MULink color="inherit" href="http://nanonord.dk/">
                NanoNord A/S
            </MULink>{' '}
            {new Date().getFullYear()}
            {'. All rights reserved'}
        </Typography>
        );
    }

}

export const Dashboard = withStyles(styles)(DashboardImpl);

