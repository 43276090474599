import { WebRequestExecutor } from "./ServerFacade";
import ApplicationState from "../ApplicationState";

export interface ServerFacadeStats {
    // "Device", "Measurements Today", "This week", "This month", "This year"
    periodicUsageStatistics(success: (data: DataTable) => void, fail: (reason: string, status?: number) => void): void;

    // "Timestamp", "Device", "SampleTag", "Job", "Algorithm", "Duration", "Chemical", "Ppm"
    measurements(request: MeasurementsRequest, success: (result: MeasurementsResults, excelUrl: string) => void, fail: (reason: string, status?: number) => void): void;

    graphs(request: GraphsRequest, success: (result: GraphsResult, excelUrl: string) => void, fail: (reason: string, status?: number) => void): void;

    lastGraphRequest(success: (result: GraphsRequest) => void, fail: (reason: string, status?: number) => void): void;
}

export default class ServerFacadeStatsImpl implements ServerFacadeStats {
    private readonly webRequestExecutor: WebRequestExecutor;
    private readonly appState: ApplicationState;

    public constructor(webRequestExecutor: WebRequestExecutor, appState: ApplicationState) {
        this.webRequestExecutor = webRequestExecutor;
        this.appState = appState;
    }

    // ----------------------------------------- ServerFacadeStats Methods -----------------------------------------

    public periodicUsageStatistics(success: (data: DataTable) => void, fail: (reason: string, status?: number) => void) {
        this.webRequestExecutor.execute('/api/statistics/periodicUsageStatistics', null
            , success
            , fail
        );
    }

    public measurements(request: MeasurementsRequest, success: (result: MeasurementsResults, excelUrl: string) => void, fail: (reason: string, status?: number) => void) {
        this.webRequestExecutor.execute('/api/statistics/measurements'
            , null
            , (result) => success(result, this.webRequestExecutor.endpointUrl() + '/api/excel/' + result.id + '?Authorization=bearer ' + this.appState.authenticationToken)
            , fail
            , request
            , 'POST'
        );
    }

    public graphs(request: MeasurementsRequest, success: (result: GraphsResult, excelUrl: string) => void, fail: (reason: string, status?: number) => void) {
        this.webRequestExecutor.execute('/api/statistics/graphs'
            , null
            , (result) => success(result, this.webRequestExecutor.endpointUrl() + '/api/excel/' + result.id + '?Authorization=bearer ' + this.appState.authenticationToken)
            , fail
            , request
            , 'POST'
        );
    }

    public lastGraphRequest(success: (result: GraphsRequest) => void, fail: (reason: string, status?: number) => void) {
        this.webRequestExecutor.execute('/api/statistics/graphs/lastRequest'
            , null
            , (result) => success(result)
            , fail
        );
    }
   
}

export interface MeasurementsRequest {
    from: Date;
    to: Date;

    devices: string[];
    jobs: string[];
    algorithms: string[];
    chemicals: string[];
    sampleTags: string[];
    sampleTagParts: string[][];
    durations: number[];
}

export interface MeasurementsResults {
    id: string;
    dataTable: DataTable;
    availableValues: any;
    limitedAvailableValues: any;
}

export interface DataTable {
    columns: string[];
    rows: any[][];
    note?: string;
}

export interface GraphsRequest extends MeasurementsRequest {
    splitKeys: string[];
    dataSeriesKeys: string[];
    includeDiagnostics: boolean;
}

export interface GraphsResult {
    id: string;
    graphs: GraphInfo[];
    availableValues: any;
    limitedAvailableValues: any;
    note: string;
}
export interface GraphInfo {
    name: string;
    dataSeries: DataSerie[];
    alarms: AlarmInfo[];
    dataRowColumns: string[];
    data: DataPoint[];
}
export interface DataSerie {
    name: string;
    key: string;
    avg: number;
    stdDev: number;
}
export interface DataPoint {
    xAxisValue: number;
    yAxisValues: any; // map from ds.key to double value
    augmentValues: any; // map from ds.key plus some additional qualifier to an object
    rowValues: any;   // map from ds.key to row(array of values)
}
export interface AlarmInfo {
    name: string;
    key: string;
    hasLevel: boolean;
    signal: string;
}
