import { observable, runInAction, action } from 'mobx';
import { ServerFacade } from './Services/ServerFacade';
import ServerFacadeImpl from './Services/ServerFacade';
import { sortList } from './Utils';
import { createMuiTheme } from '@material-ui/core';
import { CompanyInformation, DeviceInformation } from './Services/ServerFacadeDevs';
import { Preferences } from './Services/ServerFacadePreferences';
import { WebAuthenticationResponse } from './Services/ServerFacadeAuthentication';

export default class ApplicationState {

    public serverFacade: ServerFacade = new ServerFacadeImpl(this);

    // ----- From login -----

    @observable
    public authenticationToken?: string;

    @observable
    public username?: string;

    @observable
    public authenticatedDevices?: DeviceInformation[];
    @observable
    public authenticatedCompanies?: CompanyInformation[];

    @observable
    public preferences?: Preferences;

    @observable
    public theme = createMuiTheme({
        palette: {
          type: 'light',
        },
      });
;

    // ----- Emphemeral state -----

    @observable
    public snackbarMessage?: string;


    public initialize(done: () => void) {
        // See if we have auth information in local storage
        var authJson = localStorage.getItem("authentication");
        if (authJson != null) {
            var auth: WebAuthenticationResponse = JSON.parse(authJson);
            this.serverFacade.authentication().verifyToken(
                auth.authenticationToken,
                (newAuthCtx) => {
                    this.processAuthenticationInformation(newAuthCtx, done);
                },
                () => {
                    // bad authentication...bad
                    done();
                }
            );
        } else {
            done();
        }
    }

    @action
    public processAuthenticationInformation(auth: WebAuthenticationResponse, done: () => void) {
        this.authenticationToken = auth.authenticationToken;
        this.serverFacade.preferences().getPreferences(
            (preferences) => {
                runInAction(() => {
                    this.processNewPreferences(preferences);
                    this.username = auth.username;
                    this.authenticatedDevices = sortList(auth.authenticatedDevices, 'friendlyName');
                    this.authenticatedCompanies = sortList(auth?.authenticatedCompanies ?? [], 'companyName');

                    localStorage.setItem("authentication", JSON.stringify(auth));
                    done();
                });
            },
            (reason) => {
                this.authenticationToken = undefined;
                this.snackbarMessage = 'Unable to get preferences: ' + reason;
                done();
            }
        );
    }

    @action
    public processNewPreferences(preferences: Preferences) {
        let themeType: 'light' | 'dark' = preferences.useDarkMode ? 'dark' : 'light';
        this.theme = createMuiTheme({
            palette: {
                type: themeType,
            },
            });
        this.preferences = preferences;
    }


    @action
    public logout() {
        localStorage.removeItem("authentication");
        this.authenticationToken = undefined;
        this.username = undefined;
        this.authenticatedDevices = undefined;
    }

}
