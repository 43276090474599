import { WebRequestExecutor } from "./ServerFacade";
import { ChemicalStatistics } from "./ServerFacadeSampleManager";

export interface ServerFacadeDevs {
    listDevices(success: (devices: DeviceInformation[]) => void, fail: (reason: string, status?: number) => void): void;
    prepareVncConnection(deviceFpgadna: string, success: (connectionid: string) => void, fail: (reason: string, status?: number) => void): void;
    getDeviceStatistics(company: number, period: StatisticsTimespan, success: (result: DeviceStatisticsResult) => void, fail: (reason: string, status?: number) => void): void;
}

export default class ServerFacadeDevsImpl implements ServerFacadeDevs {
    private readonly webRequestExecutor: WebRequestExecutor;

    public constructor(webRequestExecutor: WebRequestExecutor) {
        this.webRequestExecutor = webRequestExecutor;
    }

    // ----------------------------------------- ServerFacadeDevs Methods -----------------------------------------

    public listDevices(success: (devices: DeviceInformation[]) => void, fail: (reason: string, status?: number) => void) {
        this.webRequestExecutor.execute('/api/devices', null, success, fail);
    }

    prepareVncConnection(deviceFpgadna: string, success: (connectionid: string) => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute('/api/devices/' + deviceFpgadna + '/vnc', null, success, fail, null, 'POST');
    }

    getDeviceStatistics(company: number, period: StatisticsTimespan, success: (result: DeviceStatisticsResult) => void, fail: (reason: string, status?: number) => void): void {
        this.webRequestExecutor.execute('/api/devices/' + company + '/statistics/' + period, null, success, fail);
    }
}

export interface DeviceInformation {
    fpgaDna: string;
    serialNumber?: string;
    friendlyName?: string;
    location?: string;
    lastSeen?: Date;
    online?: boolean;
}

export interface CompanyInformation {
    id: number;
    companyName: string;
}

export enum StatisticsTimespan {
    DAY,
    WEEK,
    MONTH
}

export interface DeviceStatisticsResult {
    firstMeasurementDate: Date;
    lastMeasurementDate: Date;
    deviceStatistics: DeviceStatistics[];
}

export interface DeviceStatistics {
    device: string;
    name: string;
    numberOfMeasurements: number;
    chemicals: ChemicalStatistics[];
}