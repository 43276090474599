import React, { useEffect, useState } from "react";
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, Input, InputLabel, makeStyles, Typography } from "@material-ui/core";
import ApplicationState from "../../ApplicationState";
import { LoadingComponent } from "../AbstractLoadingComponent";
import { Sample } from "../../Services/ServerFacadeSampleManager";
import { ColDef, DataGrid } from "@material-ui/data-grid";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => createStyles({
    inputField: {
        marginLeft: '5px',
        marginRight: '5px',
    },
    buttonWithSpacing: {
        margin: 5
    },
}));

export const ViewSample = (props: {
    appState: ApplicationState;
    company: number;
    sampleId: number;
}) => {
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [sample, setSample] = useState<Sample|undefined>(undefined);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    function loadData() {
        setLoading(true);
        const sm = props.appState.serverFacade.sampleManager();
        sm.loadSample(
            props.company,
            props.sampleId,
            (sample) => {
                setSample(sample);
                setLoading(false);
            },
            (reason) => {
                props.appState.snackbarMessage = reason;
                setLoading(false);
            }
        );
    }

    function isTestTubeInvalid(idx: number): boolean {
        const tt = sample!.testTubes[idx];
        return tt.testTubeId === undefined || tt.testTubeId === null || tt.testTubeId.trim() === '';
    }

    function markAsProcessed() {
        setLoading(true);
        const sm = props.appState.serverFacade.sampleManager();
        sm.markAsProcessed(
            props.company,
            [props.sampleId],
            () => {
                history.push('/sample/list');
            },
            (reason) => {
                props.appState.snackbarMessage = reason;
                setLoading(false);
            }
        );
    }

    function deleteSamples() {
        setLoading(true);
        const sm = props.appState.serverFacade.sampleManager();
        sm.deleteSample(
            props.company,
            sample!.id, 
            () => {
                history.push('/sample/list');
            },
            (error) => {
                setLoading(false);
                props.appState.snackbarMessage = error;
            })
    }

    useEffect(() => {
        if(sample === undefined) {
            loadData();
        }
    }, []);

    return (
        <LoadingComponent loading={loading}>
            {sample !== undefined && <>
            <form>
                <Grid container spacing={3} direction={'column'}>
                    <Grid item>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>View sample: {sample.sampleId}</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl margin="normal" disabled={true} className={classes.inputField}>
                            <InputLabel htmlFor="operatorId">Operator ID</InputLabel>
                            <Input id="operatorId" name="operatorId" value={sample.operatorId} onChange={(e) => sample!.operatorId = e.target.value} />
                        </FormControl>
                        {sample.testTubes.map((tt, idx) => 
                            <FormControl key={idx} margin="normal" error={isTestTubeInvalid(idx)} disabled={true} className={classes.inputField}>
                                <InputLabel htmlFor={'tt' + idx + 'Id'}>Tube tag {idx+1}</InputLabel>
                                <Input id={'tt' + idx + 'Id'} name={'tt' + idx + 'Id'} value={sample!.testTubes[idx].testTubeId} onChange={(e) => sample!.testTubes[idx].testTubeId = e.target.value} />
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        { renderResults(sample) }
                    </Grid>
                    <Grid item>
                        { renderTestResults(sample) }
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={'contained'} color={'default'} className={classes.buttonWithSpacing} onClick={() => markAsProcessed()}>Mark as processed</Button>
                        <Button variant={'contained'} color={'default'} className={classes.buttonWithSpacing} component={({innerRef,...props}) => <Link {...props} to={`/sample/list`}/>}>Back</Button>
                        <Button variant={'contained'} color={'secondary'} className={classes.buttonWithSpacing} onClick={() => setDeleteDialogOpen(true)}>Delete</Button>
                    </Grid>
                </Grid>
            </form>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete sample</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you really want to delete sample {sample.sampleId}? This operation CAN NOT be undone!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => deleteSamples()} color="primary">Delete</Button>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary" autoFocus>Cancel</Button>
                </DialogActions>
            </Dialog>
            </>}
        </LoadingComponent>
    );

}

function renderTestResults(sample: Sample) {
    if(!sample.measurements) {
        return <React.Fragment/>;
    }

    function twoF(v: number): string {
        if(v < 10) {
            return '0' + v;
        } else {
            return v.toString();
        }
    }

    // Build data grid columns
    const columns: ColDef[] = [
        // {
        //     field: 'id',
        //     headerName: 'ID'
        // },
        {
            field: 'ts',
            headerName: 'TS',
            valueFormatter: (v) => {
                if(v.value === undefined || v.value === null) return undefined;
                const d: Date = new Date(v.value.toString());
                return `${d.getFullYear()}/${twoF(d.getMonth()+1)}/${twoF(d.getDate())} ${twoF(d.getHours())}:${twoF(d.getMinutes())}:${twoF(d.getSeconds())}`;
            },
            width: 200
},
        {
            field: 'dev',
            headerName: 'Device',
            width: 130,
        },
        {
            field: 'tt',
            headerName: 'Tube tag',
        },
    ];
    const chemicalIds: string[] = [];
    sample.measurements!.flatMap(m => m.results).forEach(r => {
        if(!chemicalIds.includes(r.chemicalId)) {
            chemicalIds.push(r.chemicalId);
            columns.push({
                field: r.chemicalId,
                headerName: r.chemicalName,
                width: 130,
            });
        }
    });

    // Build data grid rows
    const rows: any[] = [];
    sample.measurements!.forEach(m => {
        const row: any = {
            id: m.runId,
            ts: m.time,
            dev: m.deviceName,
            tt: m.testTubeId
        };
        m.results.forEach(r => row[r.chemicalId] = `${Math.round(r.result)} ${r.resultUnits}`)
        rows.push(row);
    });

    
    const width = columns.length * 130 + 100 + 20; // columns are 100 px wide pr. default, add another 100 px for the ts columnd, and 20 px buffer
    const height = Math.max(100, rows.length * 52 + 50);
    return (
        <React.Fragment>
            <Typography color="textSecondary">Measurements, individual:</Typography>
            <div style={{width: width, height: height}}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    rowCount={rows.length}
                    disableSelectionOnClick={true}
                    pageSize={100}
                    autoHeight={rows.length > 0}
                    hideFooter={true}
                />
            </div>
        </React.Fragment>
    );
}

function renderResults(sample: Sample) {
    if(!sample.measurements) {
        return <React.Fragment>No measurements!</React.Fragment>;
    }

    // Build data grid columns
    const columns: ColDef[] = [
        {
            field: 'chemical',
            headerName: 'Chemical'
        },
        {
            field: 'avg',
            headerName: 'AVG'
        },
        {
            field: 'std',
            headerName: 'STD'
        },
    ];
    const chemicalIds: string[] = [];
    const rows: any[] = [];
    sample.measurements!.forEach(m => {
        // columns.push({
        //     field: m.runId,
        //     headerName: m.runId
        // });
        m.results.forEach(r => {
            if(!chemicalIds.includes(r.chemicalId)) {
                chemicalIds.push(r.chemicalId);
                rows.push({
                    id: r.chemicalId,
                    chemical: r.chemicalName
                });
        }});
    });

    // Build data grid rows
    rows.forEach(row => {
        sample.measurements!.forEach(m => {
            let res = m.results.find(r => r.chemicalId === row.id)?.result;
            if(res)
                res = Math.round(res);
            row[m.runId] = res;
        });
    });
    rows.forEach(row => {
        const data = sample.measurements!.map(m => row[m.runId]).filter(v => v !== undefined);
        const count = data.length;

        // avg
        const avg = data.reduce((sum, value) => sum + value, 0) / count;
        row['avg'] = Math.trunc(avg * 100) / 100;

        // std
        var squareDiffs = data.map(v =>
        {
            var diff = v - avg;
            var sqr = diff * diff;
            return sqr;
        });
        var avgSquareDiff = squareDiffs.reduce((sum, value) => sum + value, 0) / count;

        // Calculate stddev
        var stdDev = Math.sqrt(avgSquareDiff);
        row['std'] = Math.trunc(stdDev * 100) / 100;
    });

    const width = columns.length * 100 + 20; // columns are 100 px wide pr. default, and 20 px buffer
    const height = Math.max(100, rows.length * 52 + 50);
    return (
        <React.Fragment>
            <Typography color="textSecondary">Measurements, average:</Typography>
            <div style={{width: width, height: height}}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    rowCount={rows.length}
                    disableSelectionOnClick={true}
                    pageSize={100}
                    autoHeight={rows.length > 0}
                    hideFooter={true}
                />
            </div>
        </React.Fragment>
    );
}

