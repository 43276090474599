import { createStyles, Theme } from "@material-ui/core";

const drawerWidth = 240;

export const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing() * 3,
        marginRight: theme.spacing() * 3,
        [theme.breakpoints.up(400 + theme.spacing() * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing() * 2}px ${theme.spacing() * 3}px ${theme.spacing() * 3}px`,
    },
    avatar: {
        margin: theme.spacing(),
        backgroundColor: theme.palette.secondary.main,
    },

    login_root: {
        height: '100vh'
    },
    login_image: {
        backgroundImage: 'url(img/tveskaeg-benchtop-x2_2.png)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
    },
    logoutButton: {
        margin: theme.spacing(1, 1.5),
    },

    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarNanonordLogo: {
        height: 40,
        width: 'auto',
        marginLeft: theme.spacing(1)
    },
    appBarTveskaegLogo: {
        height: 40,
        width: 'auto',
        marginRight: theme.spacing(1)
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        width: `calc(100% - ${drawerWidth}px)`,
        overflow: 'auto',
    },
    dashboardcontainer: {
        padding: theme.spacing(2),
        minHeight: `calc(100% - 100px)`,
        height: '1px',
        overflow: 'auto',
    },
    dashpaper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },

    inline: {
        display: 'inline',
    },

    table: {
        minWidth: 650,
    },

    graphContainer: {
        paddingTop: theme.spacing(2),
        width: "100%"
    },

    button: {
        marginRight: theme.spacing(2),
    },
    section: {
        padding: theme.spacing(2),
    },

    selectionContainer: {
        padding: theme.spacing(2),
        width: '100%',
        margin: 0,
    },
    timeSelectionButton: {
        marginRight: theme.spacing(2),
        textAlign: 'left'
    },
    smallSquare: {
        width: theme.typography.fontSize,
        height: theme.typography.fontSize,
        display: 'inline-block',
        verticalAlign: 'text-top',
        marginRight: theme.spacing(1)
    },

    multiListSelectIcon: {
        minWidth: 20
    },
    multiListSelectCheckBox: {
        padding: 0
    },
    multiListItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },

    centeredModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    centeredModalContent: {
        top: '25%',
        margin: 'auto',
        position: 'absolute',
        minWidth: theme.spacing() * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(),
    },

    upperText: {
        verticalAlign: 'top',
        marginLeft: 5,
        marginRight: 5
    },

    buttonWithSpacing: {
        margin: 5
    },

    sampleList: {
        minHeight: 1024,
        width: '100%'
    }
});