import React from "react";
import { AbstractLoadingComponent } from "./AbstractLoadingComponent";
import { WithStyles, withStyles, TableContainer, Table, TableHead, Paper, TableRow, TableCell, TableBody, Link } from "@material-ui/core";
import { styles } from "../Styles";
import { DataTable } from "../Services/ServerFacadeStats";

export interface DataTableComponentProps {
    data: DataTable;
    dataFormatter?: (column: number, val: any) => React.ReactFragment;
    onClickHandler?: (row: any[]) => void;
}

class DataTableComponentImpl extends AbstractLoadingComponent<DataTableComponentProps & WithStyles<typeof styles>, any> {

    doRender() {
        const classes = this.props.classes;
        const dataFormatter = this.props.dataFormatter !== undefined ? this.props.dataFormatter! : (c: number, v: any) => v;
        return (
            <React.Fragment>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {this.props.data.columns.map((c, idx) => {
                                    return <TableCell key={idx} align={idx > 0 ? "right" : "left"}>{c}</TableCell>;
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.rows.map((row, idx) => (
                                <TableRow key={idx}>
                                    {row.map((val, idx) => {
                                        if (idx === 0) {
                                            let onClickHandler = this.props.onClickHandler;
                                            if(onClickHandler !== undefined) {
                                                return <TableCell key={idx} component="th" scope="row">
                                                    <Link className={classes.button} component="button" variant="inherit" color="inherit" onClick={() => onClickHandler && onClickHandler(row)}>{dataFormatter(idx, val)}</Link>
                                                </TableCell>;
                                            } else {
                                                return <TableCell key={idx} component="th" scope="row">{dataFormatter(idx, val)}</TableCell>;
                                            }
                                        } else {
                                            return <TableCell key={idx} align="right">{dataFormatter(idx, val)}</TableCell>;
                                        }
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        );
    }
}

export const DataTableComponent = withStyles(styles)(DataTableComponentImpl);

