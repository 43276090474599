import React from "react";
import ApplicationState from "../ApplicationState";
import { observer } from "mobx-react";
import { AbstractLoadingComponent } from "./AbstractLoadingComponent";
import { WithStyles, withStyles, List, Divider, ListItem, ListItemAvatar, ListItemText, FormControlLabel, Switch } from "@material-ui/core";
import { styles } from "../Styles";
import { Devices as DevicesIcon, DevicesTwoTone as DevicesTwoToneIcon } from "@material-ui/icons";
import { observable } from "mobx";
import { sortList } from "../Utils";
import { DeviceInformation } from "../Services/ServerFacadeDevs";
import { Link } from "react-router-dom";

export interface DevicesProps {
    appState: ApplicationState;
}

@observer
class DevicesImpl extends AbstractLoadingComponent<DevicesProps & WithStyles<typeof styles>, any> {

    @observable
    private devices?: DeviceInformation[];

    @observable
    private showOffline = false;

    public componentDidMount() {
        if (this.devices === undefined) {
            this.loadData();
        }
    }

    public doRender() {
        if (this.devices === undefined) {
            return (<div />);
        }

        return (
            <React.Fragment>
                <FormControlLabel control={
                    <Switch checked={this.showOffline} onChange={(e) => this.showOffline = e.target.checked} color="primary" />}
                    label="Show offline devices"
                />
                <List>
                    {this.devices?.filter(dev => this.showOffline ? true : dev.online).map((dev, idx) => {
                        var lines: React.ReactNode[] = [];
                        if (idx > 0)
                            lines.push(<Divider key={idx} variant="inset" component="li" />);
                        lines.push(this.renderDevice(dev));
                        return lines;
                    })}
                </List>
            </React.Fragment>
        );
    }

    private renderDevice(dev: DeviceInformation) {
        //const classes = this.props.classes;

        return (
            <ListItem key={dev.fpgaDna} alignItems="flex-start">
                <ListItemAvatar>
                    <Link to={'/devices/' + dev.fpgaDna} style={{ textDecoration: 'none' }}>
                        {dev.online ? <DevicesTwoToneIcon /> : <DevicesIcon />}
                    </Link>
                </ListItemAvatar>
                <ListItemText
                    primary={dev.friendlyName}
                    secondary={
                        <React.Fragment>
                            Fpga: {dev.fpgaDna}<br />
                            Serial: {dev.serialNumber}<br />
                            Last seen: {dev.lastSeen}
                        </React.Fragment>
                    }
                />
                
            </ListItem>
        );
    }

    private loadData() {
        this.loading = true;
        this.props.appState.serverFacade.devices().listDevices(
            (devices) => {
                this.devices = sortList(devices, 'friendlyName');
                this.loading = false;
            },
            (reason) => {
                this.props.appState.snackbarMessage = 'Unable to load devices: ' + reason;
                this.loading = false;
            }
        );
    }
}

export const Devices = withStyles(styles)(DevicesImpl);

