import { CircularProgress, createStyles, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { RefObject } from "react";
import ApplicationState from "../ApplicationState";

import RFB from '@novnc/novnc/lib/rfb.js';
import { observable } from "mobx";

export interface VncViewProps {
    appState: ApplicationState;
    deviceId: string;
}

const vncStyles = (theme: Theme) => createStyles({
    vncDiv: {
        height: '100%',
        width: '100%',
        // borderRadius: 25,
        // border: '2px solid black',
        // padding: 10,
        // background: 'black'
    },

    box: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
    },

    row: {
        flex: '0 1 auto',
        /* The above is shorthand for:
        flex-grow: 0,
        flex-shrink: 1,
        flex-basis: auto
        */
    },

    content: {
        flex: '1 1 auto',
    },
});

@observer
class VncViewImpl extends React.Component<VncViewProps & WithStyles<typeof vncStyles>, any> {

    @observable
    protected loading = false;

    private rfb: any = null;
    private rfbConnected = false;
    private rfbTarget: any;
    private connectionId: any;

    private desktopname: string = '';
    private statusRef: RefObject<HTMLParagraphElement> = React.createRef();

    public componentDidMount() {
        this.prepareVncConnection();
    }

    public componentWillUnmount() {
        this.tearDownConnection();
    }

    public render() {
        const classes = this.props.classes;
        var device = this.props.appState.authenticatedDevices?.find(f => f.fpgaDna === this.props.deviceId);
        return (
            <React.Fragment>
                <div className={classes.box}>
                    <div className={classes.row}><Typography component="h2" variant="h6" color="primary" gutterBottom>{device ? device.friendlyName : this.props.deviceId}</Typography></div> 
                    <div className={classes.row}>{this.loading && <CircularProgress />}<p ref={this.statusRef}>Connecting...</p></div> 
                    <div className={classes.content}><div className={classes.vncDiv} ref={e => this.gotElement(e)}></div></div> 
                </div>
            </React.Fragment>
        );
    }

    private gotElement(e: HTMLDivElement | null) {
        if(this.rfbTarget === undefined && e !== undefined) {
            this.rfbTarget = e;
            // this.mountRfbComponent();
        }
    }

    private tearDownConnection() {
        if(this.rfb) {
            if(this.rfbConnected) {
                this.rfb.disconnect();
                this.rfbConnected = false;
            }
            this.rfb = null;
        }
    }

    private prepareVncConnection() {
        if(this.rfb) {
            return; // we already have a connection
        }
        this.loading = true;
        this.props.appState.serverFacade.devices().prepareVncConnection(
            this.props.deviceId
            , (connectionId) => {
                this.loading = false;
                this.connectionId = connectionId;
                this.mountRfbComponent();
            }, (error) => {
                this.props.appState.snackbarMessage = error;
            });
    }

    private mountRfbComponent() {
        if(this.rfbTarget == null || this.connectionId == null || this.rfb !== null)
        {
            return;
        }
        const url = (this.props.appState.serverFacade.endpointUrl() + "/vnc/" + this.connectionId).replace('https', 'wss');
        this.rfb = new RFB(this.rfbTarget, url);
        this.rfb.scaleViewport = true;
        this.rfb.resizeSession = true;
        this.rfb.background = '';
        this.rfb.addEventListener("connect",  (e) => {
            this.rfbConnected = true;
            this.updateStatus('Connected to desktop ' + this.desktopname);
        });
        this.rfb.addEventListener("disconnect", (e) => {
            const shouldReconnect = this.rfbConnected;
            this.rfbConnected = false;
            if (e.detail.clean) {
                this.updateStatus('Disconnected');
            } else {
                this.updateStatus('Something went wrong, connection is closed');
            }
            if(shouldReconnect) {
                this.tearDownConnection();
                this.prepareVncConnection();
            }
        });
        this.rfb.addEventListener("desktopname", (e) => {
            this.desktopname = e.detail.name;
        });
    }

    private updateStatus(msg: string) {
        if(this.statusRef.current) {
            this.statusRef.current.textContent = msg;
        }
    }
}

export const VncView = withStyles(vncStyles)(VncViewImpl);

